import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import VisibilitySensor from "react-visibility-sensor";
import reactHtmlParser from "react-html-parser";
import {BsChevronDown} from "react-icons/bs";
import {text} from "../../styles/globalStyleVars";
import {Img} from "../Img";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {gsap} from "gsap";

const MyComponent = ({reverse, background, title, image, item, id}) => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let getAllBtn = document.querySelectorAll('.business-domain-page .accordion-header');
        getAllBtn.forEach(e => {
            e.addEventListener('click', function (i) {
                ScrollTrigger.refresh()

            });

        })

    }, [title, item])

    return (
        <StyledComponent className={`pt-200 pb-200 divider`} background={background} id={`${id ? id : 'Strength'}`}>
            <Container>
                <Row className={`address__row ${reverse ? 'flex-row-reverse' : ''}`}>
                    <Col md={reverse ? {span: 5, offset: 1} : {span: 5}}>
                        <div className='address__img '>
                            <div className="reveal">
                                <Img src={image}/>
                            </div>

                        </div>
                    </Col>

                    <Col className={` fade-up address__accordion`}
                         md={reverse ? {span: 6} : {span: 6, offset: 1}}>
                        <div className="title">
                            <Title text={title} fontSize={'28'} lineHeight={'36'} fontWeight={'500'}
                                   margin={'0 0 60px 0'} marginMobile={'0 0 40px 0'}/>
                        </div>
                        <Accordion defaultActiveKey="0">
                            {
                                item && item?.length > 0
                                && item.map((element, index) => {
                                    return (
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>
                                                {reactHtmlParser(element?.title)}
                                                <span><BsChevronDown/></span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>{reactHtmlParser(element?.short_desc)}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }


                        </Accordion>
                    </Col>


                </Row>
            </Container>

        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background: ${props => props.background};

  .accordion-item {

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .accordion-header {
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: ${text};
      //font-size: 20px;
      //font-weight: 500;
      //line-height: 28px;
      text-transform: capitalize;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(193, 193, 193, 0.5);
      width: 100%;
      text-align: left;
      transition: all .4s ease;

      font-size: 16px;
      line-height: 24px;
      font-weight: 600;


      span {
        color: white !important;
        background-color: ${text};
      }

      &.collapsed {
        color: ${text};
        border-color: rgba(193, 193, 193, 0.5);
        background: transparent;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        
        span {
          background-color: transparent;
          color: black !important;


          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: transparent;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 1px solid black;
      color: black;
      background-color: transparent;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: black;
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;

        svg {
          color: white !important;

        }
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);

      }

    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: ${text};
        border-color: rgba(193, 193, 193, 0.5);
      }
    }
  }

  .accordion-body {
    padding-top: 20px;
    padding-bottom: 40px;

    p {
      font-size: 16px;
      font-weight: 400 !important;
      line-height: 24px;
      width: 100%;
    }

    .custom-list {
      padding: 0;

      li {

        position: relative;
        padding-left: 20px;
        //padding-top: 20px;
        &:not(:last-child) {
          padding-bottom: 0px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 12px;
          left: 0;
          margin-top: -5px;
          width: 6px;
          height: 6px;
          box-sizing: content-box;
          background-color: #061524;
        }
      }

    }

    h4 {
      font-size: 16px;
      line-height: 22px;
      color: rgba(34, 31, 31, 0.2);
      margin: 0 0 10px 0;
    }

    p, a {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }

    .contact-numbers {
      margin-top: 45px;

      ul {
        li {
          a {
            margin-bottom: 13px;
            display: flex;

            span {
              font-size: 26px;
              font-weight: bold;
              line-height: 32px;
              transition: .3s ease;
            }

            &:hover span {
              color: ${text};
            }
          }

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }
    }

    .map {
      margin-top: 45px;

      iframe {
        width: 100%;
        height: 350px;
      }
    }
  }


  .address__img {
    position: relative;
    padding-top: calc(530 / 530 * 100%);
  }


  @media (max-width: 768px) {
    .address__accordion {
      min-width: 100%;

    }

    .address__form {
      min-width: 100%;
      margin-top: 60px;
    }
  }

  @media (max-width: 767px) {
    //
    //.address__row {
    //  flex-direction: column-reverse;
    //}
    .address__accordion {
      margin-top: 60px;

    }

    .address__form {
      margin-top: 0;
      margin-bottom: 60px;
    }

    .contact-numbers {
      flex-wrap: wrap;

      .col-sm-4 {
        min-width: 50%;

        &:nth-last-child(1) {
          margin-top: 30px;
          padding-left: 0;
        }
      }
    }
  }
`
export default MyComponent;