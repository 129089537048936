import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import "lightgallery.js/dist/css/lightgallery.css";
import {
    LightgalleryProvider,
    LightgalleryItem
} from "react-lightgallery";
import Title from "../Title";

const MyComponent = ({title,list}) => {
    const PhotoItem = ({image, group}) => (
        <LightgalleryItem group={group} src={image}>
            <div className="image-wrapper-light-gallery">
                <img alt='' src={image} style={{width: "100%"}}/>
            </div>
        </LightgalleryItem>
    );


    return (
        <StyledComponent className='gallery pb-150 pt-150'>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="gallery__title">
                            <Title text={title} color={'#404040'} margin={'0 0 20px 0'}/>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className={'gallery__image'}>
                <Row>
                    <LightgalleryProvider
                        lightgallerySettings={
                            {
                                download: false,
                                // thumbnail: false,
                                fullScreen: true,
                                share: false
                            }
                        }
                    >
                        {
                            list && list?.length>0 &&
                            list.map((element,index)=>{
                                return(
                                    <Col md={4} xs={12} key={index}>
                                        <div>
                                            <PhotoItem image={element?.image} group={'1'}/>
                                        </div>
                                    </Col>
                                )
                            })
                        }


                    </LightgalleryProvider>
                </Row>
            </Container>
        </StyledComponent>

    );
};
const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;

  .lg-outer .lg-item.lg-complete.lg-zoomable .lg-image{
    width: 100% !important;
  }
  .light-gallery {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .light-gallery img {
    max-width: 100%;
    height: auto;
  }

  .light-gallery .lg-outer .lg-inner {
    width: 100%;
    max-width: none;
  }

  .light-gallery .lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
    width: 100%;
    max-width: none;
  }
  .gallery__image {
    .row {
      margin-left: -25px;
      margin-right: -25px;
    }
    .col-md-4 {
      padding: 0 15px;
      margin-bottom: 30px;
    }

  }

  .image-wrapper-light-gallery{
    padding-top: calc(300 / 330 * 100%);
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    img{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .col-md-12{
    padding-left: 0px;
    padding-right: 0px;
  }

  @media (max-width: 767px) {
    .container{
      padding-left: 0px;
      padding-right: 0px;
    }
    .row {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .gallery__image .col-md-4 {
      padding: 0 ;
    }
    .title {
      h2 {
        text-align: center;
      }
    }
  }
  @media (max-width: 575px) {
    .col-md-3 {
      padding: 0 !important; ;
      margin-bottom: 0px !important;
    }
  }
`
export default MyComponent;