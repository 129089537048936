import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Title from "../components/Title";
import Button from "../components/Button";
import {hover} from "../styles/globalStyleVars";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {fetchPosts} from "../api/redux/post";

const MyComponent = () => {
    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.POSTS
        dispath(fetchPosts([api_url]))
    }, [])

    let getPost = useSelector(state => state.posts)

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Components</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <Title text={'Main <span>Title</spam>'}/>
                <Button src={'/'} text={'View All Our Projects'}/>
                <p>MCC Ltd. started its journey in 2008 at the forefront of the technological revolution in Bangladesh.
                    MCC was the country’s pioneer in mobile application development and also one of the first companies
                    to introduce large scale citizens’ service based projects in the mid 2010s. In 2018, MCC merged with
                    one of the leading infrastructure conglomerates of Bangladesh, NDE, to form NDE Infratech Ltd.
                </p>

                <div className="hover-btn hover">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <g id="Group_16047" data-name="Group 16047" transform="translate(-266 -4107)">
                            <line id="Line_3870" data-name="Line 3870" x1="10" transform="translate(267 4113)"
                                  fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                            <line id="Line_3871" data-name="Line 3871" x1="10"
                                  transform="translate(272 4108) rotate(90)" fill="none" stroke="#fff"
                                  stroke-linecap="round" stroke-width="2"/>
                        </g>
                    </svg>

                </div>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  .hover-btn {
    margin-top: 50px;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background-color: ${hover};
    margin-bottom: 40px;
    display: flex;
  }

`;

export default MyComponent;
