import React from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {ParallaxBanner, ParallaxBannerLayer, ParallaxProvider} from "react-scroll-parallax";
import {Img} from "../Img";

const InnerBanner = ({title, img,detail,subtitle}) => {

    return (
        <StyledInnerBanner className="InnerBanner" detail={detail}>
            <ParallaxProvider>

                <ParallaxBanner id="hero-banner"
                                className="InnerBanner__parallax">
                    <ParallaxBannerLayer>
                        <Img src={img? img : '/images/dynamic/project-detail.jpg'} alt=""/>
                        <Container>
                            <Row>
                                <Col md={{span:10,offset:2}}>
                                    <h1>{ReactHtmlParser(title)}</h1>
                                </Col>
                            </Row>
                        </Container>
                    </ParallaxBannerLayer>
                </ParallaxBanner>
            </ParallaxProvider>

        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  position: relative;

  .InnerBanner__parallax {
    padding-top: calc(612 / 1366 * 100%);
    position: relative;
    .container {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      z-index: 2;
      bottom: ${props => props.detail ? '80px' : '60px'};

      h1 {
        color: #FFFFFF;
        z-index: 5;
        font-size: 40px;
        font-weight: 500;
        line-height: 48px;
        margin: 0;        
      }
    }        
  }
  @media (max-width: 767px) {
    .InnerBanner__parallax {
      padding-top: calc(560 / 375 * 100%) ;
      .container {
        bottom: 60px;
        h1 {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
  }
`;

export default InnerBanner;
