import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import ProjectSingle from "../ProjectSingle";
import Button from "../Button";
import Select, {components} from "react-select";
import {hover, text} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import ReactHtmlParser from "react-html-parser";
import {useLocation} from 'react-router-dom'

const MyComponent = ({data, option, setClick, setClear, selected, setSelected, rawList}) => {

    const [isCompleted, setIsCompleted] = useState(false)

    const [index, setIndex] = useState(Number(sessionStorage.getItem('load')))

    const initialPosts = data?.slice(0, index);
    const location = useLocation()

    useEffect(() => {
        if (!sessionStorage.getItem('load')) {
            sessionStorage.setItem('load', 6)
            setIndex(6)
        }

    }, [])

    const loadMore = () => {

        // setIndex(index + 6)
        setClear(false)

        if (index >= data?.length) {
            setIsCompleted(true)
            document.querySelector('.load-more').classList.add('hide');

        } else {
            setIsCompleted(false)
        }
        if (data?.length >= Number(sessionStorage.getItem('load')))
            sessionStorage.setItem('load', Number(sessionStorage.getItem('load')) + 6)
        setTimeout(() => {
            setIndex(Number(sessionStorage.getItem('load')))
        }, 300)

    }

    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `${hover}` : '#221F1F',
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
            margin: 0,
            fontSize: 14,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 600 : 400,
            "&:hover": {
                backgroundColor: `rgba(232, 231, 231, 0.8)`,
                color: '#FFF',
                cursor: 'pointer'
            },

        }),
        menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `${hover}` : 'rgba(255,255,255,0)',
            margin: 0,
            borderRadius: 20,
            fontSize: 14,
            zIndex: 10
            // width: 200,
        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? `${hover}` : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            // fontSize: 14,
        }),

    };


    //Filter the options
    // const options = option?.map(element => ({value: element?.name, label:  ReactHtmlParser(element?.name)}));

    let options = [
        {
            "value": "Power &amp; Energy",
            "label": "Power & Energy"
        },
        {
            "value": "Transportation Technology",
            "label": "Transportation Technology"
        },
        {
            "value": "Citizens’ Services",
            "label": "Citizens’ Services"

        },
        {
            "value": "Science &amp; Attraction",
            "label": "Science & Attraction"
        },


        {
            "value": "Security &amp; Surveillance",
            "label": "Security & Surveillance"
        },

    ]

    let filteredList = selected === '' ? rawList : rawList?.filter(element => element?.type?.[0]?.name === selected?.value);

    const handleStatus = (value) => {
        setSelected(value);
    }
    const [width, setWidth] = useState()

    useEffect(() => {
        setWidth(window.innerWidth)
    }, [])

    //onclick get the option that is selected and make it active
    const getInfo = (index, type) => {
        setClick(type)
        let navLinks = document.querySelectorAll('.inner-menu ul li a');
        for (let j = 0; j <= navLinks.length; j++) {
            if (j === index) {
                navLinks[j]?.classList?.add('active')
            } else navLinks[j]?.classList?.remove('active')
        }

        // clear local storage & states
        // localStorage.clear('load')
        // setIndex(6)
    }

    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="7.828" viewBox="0 0 12.828 7.828">
            <g id="Group_6" data-name="Group 6" transform="translate(-442.086 -92.086)">
                <line id="Line_4" data-name="Line 4" x2="5" y2="5" transform="translate(443.5 93.5)" fill="none"
                      stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                <line id="Line_5" data-name="Line 5" x1="5" y2="5" transform="translate(448.5 93.5)" fill="none"
                      stroke="#fff" stroke-linecap="round" stroke-width="2"/>
            </g>
        </svg>
    };
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };


    // after back from detail page keep tab active
    useEffect(() => {
        const getAllTab = document.querySelectorAll('.tab li a')
        getAllTab && getAllTab.forEach((e, i) => {
            e.addEventListener('click', function () {
                sessionStorage.setItem('tabNumber', i)
            });
        })
    }, [])

    // useEffect(() => {
    //     if (Number(sessionStorage.getItem('tabNumber'))) {
    //         const getAllTab = document.querySelectorAll('.tab li a');
    //         setTimeout(() => {
    //             getAllTab[Number(sessionStorage.getItem('tabNumber'))]?.click()
    //         }, 500)
    //     }
    // }, [location.pathname])

    return (
        <StyledComponent className={'pt-100 pb-200 divider'}>
            <Container>
                <Row>
                    <Col md={12} className="menus">
                        <div className="drop">
                            <Select
                                classNamePrefix="filter"
                                onChange={handleStatus}
                                isSearchable={false}
                                styles={customStyles}
                                components={{DropdownIndicator}}
                                options={options}
                                placeholder={'All'}
                                defaultValue={options?.[0]?.value}
                            />
                        </div>
                        <div className="inner-menu">
                            <ul className="tab">
                                <li onClick={() => getInfo(0, 'All')}><a className={"active"}>All</a></li>
                                {
                                    options && options?.length > 0 &&
                                    options.map((element, index) => {
                                        return (
                                            <li key={index + 1} onClick={() => getInfo(index + 1, element?.value)}><a
                                                className={""}>{reactHtmlParser(element?.value)}</a></li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Col>
                    <div className="clearfix"></div>
                    {width > 767 ?
                        initialPosts?.length > 0 ?
                            initialPosts && initialPosts?.length > 0 &&
                            initialPosts.map((element) => {
                                return (
                                    <Col md={6} key={element?.ID}>
                                        <ProjectSingle
                                            img={element?.thumb ? element?.thumb : '/images/static/nde_projects_default_thumb.jpg'}
                                            title={element?.title}
                                            desc={element?.post_title}
                                            link={`project/${element?.post_name}`}/>
                                    </Col>
                                )

                            }) : 'No data is found' :
                        filteredList?.length > 0 ?
                            filteredList && filteredList?.length > 0 &&
                            filteredList.map((element) => {
                                return (
                                    <Col md={6} key={element?.ID}>
                                        <ProjectSingle img={element?.thumb} title={element?.title}
                                                       desc={element?.post_title}
                                                       link={`project/${element?.post_name}`}/>
                                    </Col>
                                )

                            }) : 'No data is found'
                    }

                    {data?.length >= index && (
                        <Col md={12} className="load-more" onClick={loadMore}>
                            <Button
                                text={'Load More'}
                                width={'15%'}
                            />
                        </Col>
                    )}
                </Row>
            </Container>

        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  .load-more.hide {
    display: none;
  }

  .load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .inner-menu {
    padding: 0px 0 70px;

    @media (max-width: 767px) {
      display: none;
      padding: 20px 0 !important;
      .col-md-12 {
        padding: 0;
      }
    }

    ul {
      display: inline;
      width: 100%;

      li {
        &:not(:nth-last-of-type(1)) {
          margin-right: 30px;
        }

        margin-bottom: 30px;
        display: inline-block;
        width: auto;

        a {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          color: #1961AC;
          border: 1px solid #1961AC;
          padding: 12px 36px;
          border-radius: 50px;
          height: 40px;
          justify-content: center;
          align-items: center;
          display: flex;
          position: relative;
          cursor: pointer;
          transition: 0.7s all ease !important;


          &.active {
            background: #1961AC;
            color: #F0EDE3;
          }

          &:hover {
            background: #1961AC;
            color: #F0EDE3 !important;
          }

          &.active {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .drop {
    display: none;
  }

  @media (max-width: 767px) {
    .menus {
      margin-bottom: 60px;

      .drop {
        display: block;
      }
    }
  }

  .css-13cymwt-control {
    border-radius: 20px;
    background-color: ${hover};
  }

  .css-1jqq78o-placeholder {
    margin-left: 12px;
    color: #FFFFFF;
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }

  .css-1s4cg4l-indicatorContainer {
    margin-right: 12px;
  }

  .css-1dimb5e-singleValue {
    color: white;
    margin-left: 12px;
  }

  .filter {

    &__placeholder {
      color: white !important;
      font-size: 16px;
      //font-weight: 500;
      margin-left: 19px;
    }

    &__control {
      //padding-left: 0;
      //padding-right: 0;
      border-radius: 20px;


      &--is-focused {
        background-color: ${hover};
        border: none;
        //border-bottom: 1px solid #FFF;
      }
    }


    &__indicator {
      margin-right: 12px;
    }

    &__value-container {
      //padding-left: 0;
      //padding-right: 0;
      //cursor: pointer !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1550px) {
    .dc-btn {
      width: 18% !important;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .dc-btn {
      width: 25% !important;
    }
  }
  @media (max-width: 767px) {
    .dc-btn {
      width: 40% !important;
    }
  }
  @media (max-width: 400px) {
    .dc-btn {
      width: 48% !important;
    }
  }


`
export default MyComponent;
