import {createGlobalStyle, css} from 'styled-components';
import {hover, text} from './globalStyleVars';


function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }

  //font connect 
  //@font-face {
  //  font-family: 'Graphik';
  //  src: url('fonts/Graphik-Bold.woff2') format('font-woff2'),
  //  url('fonts/Graphik-Bold.woff') format('font-woff');
  //  font-weight: bold;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //
  //@font-face {
  //  font-family: 'Graphik';
  //  src: url('fonts/Graphik-Regular.woff2') format('font-woff2'),
  //  url('fonts/Graphik-Regular.woff') format('font-woff');
  //  font-weight: normal;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Graphik';
  //  src: url('fonts/Graphik-Medium.woff2') format('font-woff2'),
  //  url('fonts/Graphik-Medium.woff') format('font-woff');
  //  font-weight: 500;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Graphik';
  //  src: url('fonts/Graphik-Semibold.woff2') format('font-woff2'),
  //  url('fonts/Graphik-Semibold.woff') format('font-woff');
  //  font-weight: 600;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Graphik';
  //  src: url('fonts/Graphik-Black.woff2') format('woff2'),
  //  url('fonts/Graphik-Black.woff') format('woff');
  //  font-weight: 900;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //@font-face {
  //  font-family: 'Graphik';
  //  src: url('fonts/Graphik-Light.woff2') format('woff2'),
  //  url('fonts/Graphik-Light.woff') format('woff');
  //  font-weight: 300;
  //  font-style: normal;
  //  font-display: swap;
  //}
  //
  //


  @font-face {
    font-family: "Graphik";
    src: url("/fonts/Graphik-Black.woff2") format("woff2"), url("/fonts/Graphik-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik";
    src: url("/fonts/Graphik-Bold.woff2") format("woff2"), url("/fonts/Graphik-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik";
    src: url("/fonts/Graphik-Regular.woff2") format("woff2"),
    url("/fonts/Graphik-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: "Graphik";
    src: url("/fonts/Graphik-Medium.woff2") format("woff2"),
    url("/fonts/Graphik-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: "Graphik";
    src: url("/fonts/Graphik-Light.woff2") format("woff2"), url("/fonts/Graphik-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik";
    src: url("/fonts/Graphik-Semibold.woff2") format("woff2"),
    url("/fonts/Graphik-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }


  //

  body {
    font-family: 'Graphik', Arial, Helvetica, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;


    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 22px;

    }


    scrollbar-width: none;


    &::-webkit-scrollbar {
      display: none;
    }

    &::-moz-scrollbar {
      display: none;

    }

    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none;
  }

  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  p, a, h4, h3, h5, h6 {
    color: ${text};
    font-weight: 300;
    margin: 0;
  }

  h1, h2 {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: #061524;
    padding: 60px 40px;
    @media (max-width: 767px) {
      //padding: 60px 0px;
      padding-left: 15px;
      padding-right: 15px;

    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 60px;

    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border-bottom: 1px solid white;
      -webkit-text-fill-color: white;

    }

    .form-control {
      height: 50px;
      padding-left: 0;
      border: none;
      border-bottom: 1px solid #FFFFFF;
      background-color: transparent;
      border-radius: 0px;
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 40px;
    }

    ::placeholder {
      color: rgba(255, 255, 255, 0.5) !important;
      font-size: 16px;
      font-weight: 300;
    }

    textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid #FFF;
      background-color: transparent;
      border-radius: 0px;
      font-size: 16px;
      max-height: 110px;
      min-height: 110px;
      min-width: 100%;
      max-width: 100%;
      outline: none;
      color: #ffffff;
      margin-top: 10px;
      margin-bottom: 40px;
    }

    .css-yk16xz-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #FFF !important;
    }

    .filter {
      &__placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
        font-size: 16px;
        font-weight: 300;
      }

      &__control {
        padding-left: 0;
        padding-right: 0;

        &--is-focused {
          background-color: transparent !important;
          border: none;
          border-bottom: 1px solid #FFF;
        }
      }


      &__indicator {
        margin-right: 0;
        padding: 0;
      }

      &__value-container {
        padding-left: 0;
        padding-right: 0;
        cursor: pointer !important;
      }
    }

    .css-1uccc91-singleValue {
      color: #ffffff !important;
    }

    @media (max-width: 991px) {
      //padding: 0 !important;
    }

    @media (max-width: 575px) {
      .filter__control {
        margin-bottom: 25px !important;
      }
    }

    .form-right {
      position: relative;

      &__box {
        //margin-right: 40px;

        height: 40px;
        width: 175px;
        border: none;

        &:hover {
          border: none;
        }

        input {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          border: 2px dashed #FFFFFF;
          border-radius: 26px;
          overflow: hidden;
          @media (max-width: 1200px) {
            width: 94%;
          }

          &:focus {
            outline: none;
            border: 2px dashed #FFFFFF;
          }

          &:after {
            background-image: url('/images/static/upload-svg.svg');
            content: 'Attach CV';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            bottom: 0;
            transition: all 0.25s ease-in-out;
            background-color: #061524;
            //background-color: transparent;
            z-index: 2;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            white-space: normal;
            text-align: center;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: 30px 50%;
            padding-left: 30px;
            transition-delay: 0.15s;

            @media (max-width: 1200px) {
              background-position: 20px 50%;
            }
          }

          @media (max-width: 767px) {
            width: 85%;
          }
        }

        @media (max-width: 767px) {
          margin-bottom: 40px;
          width: 100%;
        }
      }
    }

  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 120px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 120px;
    }
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-120 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pb-120 {
    padding-bottom: 120px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .divider {
    margin-top: -2px;
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    //opacity: 0.3 !important;
    pointer-events: none;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  .gph_modal {
    background: #F6F6F6;
    overflow: hidden !important;
    z-index: 9999;

    .hover {
      height: 56px;
      width: 56px;
      background-color: #fff !important;
      border-radius: 50%;

      svg {
        //font-size: 17px;
      }
    }

    .modal-body {
      padding-top: 60px !important;
      @media (max-width: 1200px) {
        .col-lg-5 {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
      @media (max-width: 767px) {
        .col-lg-5, .col-sm-12 {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }

        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .modal-dialog {
      margin: 0;
      width: 100%;
      height: 100%;
      background: #F4F4F4;
      max-width: 100%;

      .btn-close {
        display: none;
      }

      .modal-header {
        padding: 0;
        margin-bottom: 0;
        background: #F9F9F9;
        border-bottom: transparent;
        position: relative;

        .h4 {
          color: #222222;
          font-weight: 500;
        }

        .header_wrap {
          display: flex;
          justify-content: space-between;
          align-items: start;
          padding: 80px 0px 20px;
          border-bottom: 1px solid #404040 !important;
          margin: 0px 15px;
          position: relative;

          @media (max-width: 1499px) {
            margin: 0px 0px;

          }


          .btn-circle {
            position: absolute;
            right: -100px;
            top: 20px;
            height: 56px;
            width: 56px;
            border-radius: 50%;
            background-color: ${text};
            @media (max-width: 767px) {
              right: 15px;
              top: 20px;
            }

            svg line {
              transition: .4s ease;
            }

            &:after {
              background-color: ${hover};
            }

            &:hover {
              svg {
                line {
                  stroke: #ffffff;
                }
              }
            }

            //&:hover {
            //  svg {
            //    stroke: red; /* Replace 'red' with your desired color */
            //  }
            //}

            //&:hover {
            //  svg {
            //    stroke: white;
            //  }
            //
            //  .btn-circle:after {
            //    top: 0;
            //  }
            //}
          }


          h3 {
            font-size: 32px;
            line-height: 36px;
            font-weight: 600;
            //margin-left: 16px;
            margin-right: 4px;

            @media (max-width: 767px) {
              margin-top: 44px;
              margin-left: 15px;
            }
            @media (min-width: 1550px) {
              font-size: 40px;
              line-height: 56px;
            }
            @media (max-width: 767px) {
              font-size: 24px;
              line-height: 32px;
            }
          }


          .close_button {
            cursor: pointer;
            margin-right: 16px;
            position: absolute;
            top: 20px;
            right: 20px;

            &:after {
              content: '';
              position: absolute;
              height: 0%;
              width: 0%;
              border-radius: 50%;
              color: #FFFFFF;
              background-color: red;
                // background-color: ${hover};
              opacity: 0;
              transition: all .4s ease;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              margin: auto;

            }

            &:hover:after {
              height: 100%;
              width: 100%;
              opacity: 1;

            }

            &:hover {
              line {
                stroke: #FFF;
              }
            }

            //&:hover {
            //  svg {
            //    g {
            //      g {
            //        path {
            //          stroke: #FFFFFF !important;
            //        }
            //      }
            //    }
            //  }
            //
            //}
          }

        }

      }

      .custombar {
        position: relative;

        &:after {
          z-index: 1;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100px;
          background: transparent;
          //background: linear-gradient(to bottom, rgba(244, 244, 244, 0) 0%,
          //rgba(244, 244, 244, 1) 100%);
          //@media (max-width: 767px) {
          //  background: linear-gradient(to bottom, rgba(244, 244, 244, 0) 0%,
          //  rgba(244, 244, 244, 0.5) 100%);
          //}
        }
      }

      .modal-content {
        height: 100vh;
        padding: 0;
        background: #F9F9F9;
        border: none;

        .left_col {
          padding: 60px 0px 60px;

          .custom-list {
            padding: 0;

            li {

              position: relative;
              padding-left: 20px;
              padding-top: 20px;

              &:not(:last-child) {
                border-bottom: 1px solid rgba(217, 217, 217, 0.5);
                padding-bottom: 20px;

              }

              &:before {
                content: '';
                position: absolute;
                top: 30px;
                left: 0;
                //transform: translate(-50%,-50%);
                margin-top: -5px;
                width: 10px;
                height: 10px;
                box-sizing: content-box;
                background-color: #C1C1C1;
              }
            }

          }

          h3 {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #404040;

            &:not(:first-child) {
              margin-top: 40px;
            }
          }

          p {
            font-weight: 400;
            color: #222222;
            margin-top: 20px;
            //margin-bottom: 40px;

            &.deg {
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              color: #FB030C;
              margin: 0 0 40px;
            }
          }

          @media (max-width: 767px) {

            .content {
              padding-left: 15px;
              padding-right: 15px;

            }

            h5 {
              margin-top: 30px;
            }
          }
        }

        .right_col {
          //padding: 60px 15px 60px;
          margin-left: -15px;
          margin-right: -15px;

          .custombar {
            &:after {
              display: none;
            }
          }

          .right_col_img {
            position: relative;
            padding-top: calc(600 / 500 * 100%);
          }
        }

      }
    }

    .simplebar-track.simplebar-vertical {
      display: none;
    }

    @media (max-width: 767px) {
      overflow-y: scroll !important;
      .custombar {
        position: relative;
        height: auto !important;

        .simplebar-content-wrapper {
          overflow: auto;
        }
      }

      .modal-content {
        overflow: hidden !important;
      }

      .row {
        //flex-direction: column-reverse;

        .left_col {
          padding: 0 0px 40px !important;
        }

        .right_col {
          //padding: 40px 15px !important;
        }
      }

    }

    @media (min-width: 767px) {
      .main_scroll {
        position: relative;
        height: 100% !important;

        .simplebar-content-wrapper {
          overflow: scroll;
        }
      }
    }
  }


  .gph_upload {
    position: relative;
    cursor: pointer;

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      content: attr(text);
      background: #F9F9F9;
      z-index: 1;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: rgba(34, 34, 34, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.7s all ease;
    }

    &:hover {
      &:after {
        background: #E9E9E9;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      z-index: 2;
    }

    svg {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 100%;
      margin: auto;
      background-color: ${text};
      transition: all .5s ease;
      border-radius: 50%;
    }

    &:hover {
      &:after {
        top: 0;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .menu-desktop {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-down .menu-bar:before {
    display: none;
  }

  //.scroll-up .menu-desktop {
  //  background-color: #fff;
  //
  //  .menu-desktop__hamburger__lines {
  //    &__inner .line {
  //      background-color: #191818;
  //    }
  //
  //    p {
  //      color: #191818;
  //    }
  //  }
  //
  //  .menu-desktop__hamburger .controller svg {
  //    path {
  //      fill: #191818;
  //    }
  //
  //    line {
  //      stroke: #191818;
  //    }
  //  }
  //
  //  //.dc-btn a {
  //  //  color: #191818;
  //  //
  //  //  &:hover {
  //  //    color: #191818 !important;
  //  //  }
  //  //
  //  //  &:after, &:before {
  //  //    box-shadow: 0 0 0 1px #191818;
  //  //  }
  //  //}
  //
  //  .dc-btn a {
  //    display: none;
  //  }
  //}

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }


  .menu-visible {
    background-color: #061524 !important;

    &:after {
      background-color: rgba(255, 255, 255, 0.5) !important;
    }

    .logo {
      img {
        display: none;

        &:nth-last-of-type(1) {
          display: block !important;
        }

      }
    }
  }


  //menu fixed
  .scroll-down .desktop-menu {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-down .menu-bar {
    transform: translate3d(0, -100%, 0);
  }

  //remove dropdown
  //.scroll-down {
  //  .desktop-menu__top .contact-button__content {
  //    display: none !important;
  //    height: 0 !important
  //  }
  //}

  .scroll-up {
    .desktop-menu, .menu-bar {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 3%) !important;
      //background-color: rgba(25, 97, 172, 0.8) !important;
    }

    .desktop-bg {
      background: transparent !important;
    }

    .menu-bar:before {
      display: none;
    }

    .desktop-menu .dc-btn a {
      border-color: #FFF !important;;
    }

    .contact-button .dc-btn {
      a:before {
        background-color: #fff;
      }

      &:hover span {
        color: ${hover};
      }
    }

    .Mobile-menu-wrap:after {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 3%) !important;
    }
  }

  .menu-showed {
    height: 100vh;
    overflow: hidden;

    .menu-bar:before {
      display: none;
    }
  }

  .contact-slided {
    .mobile-menu__items {
      transform: translate3d(-100vw, 0px, 0px) !important;
    }

    .contact-info {
      //transform: none !important;
      transform: translate3d(0, 0, 0) !important;
      //display: none;
    }
  }

  //------------------------animation

  .split-up {
    width: 100%;
  }

  //text animation 
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }


  //text animation 
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }


  //modal customisation
  .modal-backdrop {
    //z-index: 9999;
    background-color: rgba(0, 0, 0, 0.4);
    min-width: 100%;
  }

  .modal {
    border-radius: 0;

    .modal-content {
      border-radius: 0;
    }
  }

  //get in touch modal 
  .modal.get-touched {
    z-index: 999999;
    height: 100vh;
    right: 0;
    left: auto;
    width: 510px;
    opacity: 1 !important;
    //background-color: #F6F6F6;

    .modal-dialog {
      min-width: 100%;
      margin: 0;
      transform: translateX(100%);

      .modal-content {
        height: 100vh;
        width: 100%;
        right: 0;
        background-color: #F6F6F6;
        padding: 80px 100px 40px 40px;
      }
    }

    &.show {
      .modal-dialog {
        transform: none;
      }
    }


    .slide-form {


      h4 {
        font-size: 28px;
        line-height: 36px;
        color: #404040;
        margin-bottom: 60px;
        font-weight: 600;
      }

      .form-control {
        margin-bottom: 40px;
        border: 0;
        border-bottom: 1px solid rgba(64, 64, 64, 0.5);
        border-radius: 0;
        padding-left: 0;
        font-size: 16px;
        line-height: 24px;
        background-color: transparent;


        ::placeholder {
          color: rgba(64, 64, 64, 0.5);
        }
      }

      textarea {
        max-height: 80px;
      }

      .close-btn {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 20px;
        right: 20px;
        position: absolute;
        background-color: #fff;
        cursor: pointer;

        line {
          transition: .3s ease;
        }

        &:hover {
          svg {
            line {
              stroke: #FFF
            }
          }
        }
      }

    }

    @media (max-width: 767px) {
      width: 100%;
      .modal-dialog .modal-content {
        padding: 80px 20px 40px 20px;
      }
    }
  }

  //management modal
  .modal.management-team {
    z-index: 999999;
    height: 100vh;
    right: 0;
    left: auto;
    width: 508px;
    opacity: 1 !important;
    overflow: hidden;

    &.show {
      .modal-dialog {
        transform: none;
      }
    }

    .modal-dialog {
      min-width: 100%;
      margin: 0;
      transform: translateX(100%);

      .modal-content {
        height: 100vh;
        width: 100%;
        right: 0;
        background-color: #F6F6F6;
        padding: 80px 40px 30px 40px;
      }
    }

    .dc-popup__close {
      height: 52px;
      width: 52px;
      position: fixed;
      top: 40px;
      right: 70px;
      border-radius: 50%;
      border: 1px solid ${text};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        position: relative;
        z-index: 2;

        line {
          transition: .3s ease;
        }
      }

      &.hover:after {
        border-radius: 50%;
      }

      &:hover {
        line {
          stroke: #FFF;
        }
      }
    }

    .dc-btn {
      opacity: 1 !important;
      transform: none !important;
      visibility: visible !important;
    }

    .dc-popup__header {
      width: 100%;
      padding-right: 20%;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(217, 217, 217, 0.5);
      margin-bottom: 40px;
      margin-top: 20px;

      &__img {
        position: relative;
        padding-top: calc(370 / 370 * 100%);
        margin-bottom: 20px;
      }

      h4 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #C1C1C1;
      }

      &__pricing {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        h5 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }

        h6 {
          color: ${hover};
          font-size: 36px;
          line-height: 28px;
          font-weight: 600;
        }
      }
    }

    .dc-popup__content {
      //padding-right: 30px;
      margin-bottom: 40px;

      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 100px;
      }

      ul {
        padding-left: 15px;
        padding-right: 30px;

        p {
          margin-bottom: 0;
          margin-left: -15px;
        }

        li {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          list-style: disc outside;
        }
      }
    }

    .simplebar-track.simplebar-vertical {
      right: 0px;
    }

    @media (max-width: 1200px) {
      width: 90%;
    }

    @media (max-width: 991px) {
      width: 100%;
      .dc-popup__header {
        h4 {
          font-size: 24px;
          line-height: 28px;
        }
      }

      .dc-popup__header__pricing {
        h6 {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }

    @media (max-width: 767px) {
      overflow: scroll;
      padding: 50px 20px;
      .dc-popup__header {
        padding-right: 70px;
      }

      .dc-popup__header__pricing__left, .dc-popup__header__pricing__right {
        min-width: 100%;
        margin-bottom: 40px;
      }

      .dc-popup__content {
        padding-right: 0;
      }

      .dc-popup__close {
        right: 20px;
      }
    }

    .simplebar-content {
      padding-bottom: 100px !important;
    }
  }

  //page loader
  .page-loader {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgb(6, 21, 36);
    z-index: 999999999;
    display: none;
    opacity: 0;
    //display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 80px;
      opacity: 0;
      @media (max-width: 767px) {
        margin-left: 5%;
      }
    }
  }


  .parallax-image {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .modal_video_popup {
    .modal-content, .modal-video, .modal-dialog {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

    }

    .modal-dialog {
      border: 0 !important;
      margin: 0 !important;
      max-width: unset !important;
      width: 100% !important;
      height: 100% !important;
    }

    .modal-body {
      border: none !important;
      box-shadow: none !important;
    }

    .modal-close {
      opacity: 0;
    }
  }


`;



