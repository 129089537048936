import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {text} from "../../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data, id}) => {


    // gsap.registerPlugin(ScrollTrigger);

    const [offset, setOffset] = useState(0)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container')?.offsetLeft)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container')?.offsetLeft)
            }
        })
    }, [])


    return (
        <StyledComponent id={id} className={'ministry divider'}>
            <div className={'ministry__container'}
                 style={{paddingLeft: offset + 30 + 'px'}}>
                <Row>
                    <Col md={{span: 3, offset: 1}} className={''}>
                        {/*<img src={data?.logo} alt=""/>*/}
                        <h3>{data?.title ? data?.title : 'Our strengths'}</h3>
                    </Col>
                    <Col md={{span: 5, offset: 2}}>
                        <ul className={'customs-list'}>
                            {
                                data?.list && data?.list?.length > 0 &&
                                data?.list.map((element, index) => {
                                    return (
                                        <>
                                            {
                                                element?.list && <li key={index}>{reactHtmlParser(element?.list)}</li>
                                            }
                                        </>

                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background: #061524;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 50%;
    bottom: 0;
    background-color: #E9E9E9;
  }


  .ministry__container {
    position: relative;
    z-index: 2;

    .customs-list {
      padding: 0;
      counter-reset: listItem;


      li {

        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        position: relative;
        //padding-left: 62px;
        color: #FFFFFF;

        //@media (max-width: 767px) {
        //  padding-left: 82px;
        //}

        &:not(:last-child) {
          border-bottom: 1px solid rgba(217, 217, 217, 0.5);
          padding-bottom: 40px;
        }

        &:not(:first-child) {
          padding-top: 40px;

        }

        //&:before {
        //  content: counter(listItem);
        //  counter-increment: listItem;
        //  position: absolute;
        //  top: 55px;
        //  left: 0;
        //  //transform: translate(-50%,-50%);
        //  margin-top: -15px;
        //  width: 10px;
        //  height: 10px;
        //  box-sizing: content-box;
        //  //background-color: #C1C1C1;
        //
        //}
        //
        //&:first-child {
        //  &:before {
        //    top: 15px !important;
        //  }
        //}
      }

    }

    .row {
      background-color: #404040;
      padding: 120px 0;
      @media (max-width: 767px) {
        padding: 80px 0;
      }
    }
  }

  img {
    height: 100px;
  }

  .col-sm-6 {
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 100px;
    }

  }

  h3 {
    font-size: 40px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 48px;
    //margin-top: 40px;
  }


  @media (max-width: 767px) {
    h3 {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 60px;

    }

    .ministry__container .customs-list li {
      font-size: 16px;
      line-height: 20px;
    }

    .ministry__container {
      padding-left: 0 !important;

      .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }

      .col-sm-2, .col-sm-7 {
        min-width: 100%;
        margin: 0;
      }

      .col-sm-2 {
        padding: 0 30px !important;
        margin-bottom: 60px;

        img {
          height: 70px;
        }
      }

      .col-sm-6 {
        min-width: 100%;

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 60px;
        }

        h4 {
          font-size: 60px;
          line-height: 60px;
          padding-bottom: 10px;
          margin-bottom: 20px;
          letter-spacing: -4px;

          &:after {
            width: 100%;
          }
        }
      }
    }
  }
`;

export default MyComponent;
