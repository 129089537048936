import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "./Img";
import {hover} from "../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({img, title, desc, link}) => {
    return (
        <StyledComponent className={'projects-single'}>
            <Link to={link}/>
            <div className="project-single__img parallax-img">
                {img && <Img src={img}/>}

                <div className="btn-circle hover">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <g id="Group_16047" data-name="Group 16047" transform="translate(-266 -4107)">
                            <line id="Line_3870" data-name="Line 3870" x1="10" transform="translate(267 4113)"
                                  fill="none"
                                  stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                            <line id="Line_3871" data-name="Line 3871" x1="10"
                                  transform="translate(272 4108) rotate(90)"
                                  fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                        </g>
                    </svg>

                </div>
            </div>
            {title && <h4>{reactHtmlParser(title)}</h4>}
            {desc && <p>{reactHtmlParser(desc)}</p>}



        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  margin-bottom: 80px;
  position: relative;

  a {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .global-image {
    overflow: hidden;

    img {
      //transform: scale(1.01);
      //transition: 1s ease;
    }
  }

  .project-single__img {
    padding-top: calc(300 / 570 * 100%);
    position: relative;
  }


  h4 {
    margin-top: 20px;
    color: #C1C1C1;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  p {
    font-weight: 500;
    padding-right: 60px;
  }

  .btn-circle {
    position: absolute;
    right: 30px;
    bottom: -26px;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background-color: ${hover};
  }

  &:hover {
    .btn-circle:after {
      top: 0
    }

    img {
      //transform: scale(1.04);
    }
  }

  @media (max-width: 650px) {
    margin-bottom: 40px;
    .btn-circle {
      right: 45px;
      width: 40px;
      height: 40px;
      bottom: -20px;
    }

    p {
      padding-right: 0;
    }

  }


`;

export default MyComponent;
