import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import homeReducer from '../redux/Home'
import aboutReducer from '../redux/About'
import businessReducer from '../redux/Business'
import contactReducer from '../redux/Contact'
import careerReducer from '../redux/Career'
import projectReducer from '../redux/Project'
import mediaReducer from '../redux/media'
import esgReducer from '../redux/Esg'
import globalReducer from '../redux/global'

const store = configureStore({
    reducer: {
        globalReducer,
        posts: postReducer,
        home: homeReducer,
        about: aboutReducer,
        business: businessReducer,
        contact: contactReducer,
        career: careerReducer,
        media: mediaReducer,
        project: projectReducer,
        esg: esgReducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

