import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import reactHtmlParser from "react-html-parser";
import {Autoplay, EffectCreative, Navigation, Pagination} from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Col, Container, Row} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {Img} from "../Img";
import Title from "../Title";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/About";
import {useDispatch} from "react-redux";

const MyComponent = ({reverse, title, short_desc, images}) => {

    const nextRef = useRef();
    const prevRef = useRef();
    const [paddingTop, setPaddingTop] = useState(200)

    // api call


    // offset

    const [offset, setOffset] = useState(90)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container')?.offsetLeft)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container')?.offsetLeft)
            }
        })


    }, [title, short_desc])

    // const [offset, setOffset] = useState(0)
    // useEffect(() => {
    //     setOffset(document.querySelector('.container').offsetLeft)
    // }, [])


    // style={short_desc && {paddingLeft: offset + 15 + 'px', paddingRight: 0}}


    return (
        <StyledComponent offset={offset}>
            {
                reverse ?
                    <Container fluid className={'spec-with-img'}
                               style={{paddingLeft: 0}}>
                        <Row className={'mobile-reverse'}>
                            <Col md={6} className={'spec-with-img__slider'}>

                                <div className={`${images && images.length <= 1 ? 'opacity' : ''} demo-two__btn__prev`}
                                     ref={prevRef}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                         viewBox="0 0 7.828 12.828">
                                        <g id="Group_6" data-name="Group 6"
                                           transform="translate(-92.086 454.914) rotate(-90)">
                                            <line id="Line_4" data-name="Line 4" x2="5" y2="5"
                                                  transform="translate(443.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_5" data-name="Line 5" x1="5" y2="5"
                                                  transform="translate(448.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                </div>
                                <div
                                    className={`${images && images.length <= 1 ? 'opacity' : ''} demo-two__btn__next`}
                                    ref={nextRef}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                         viewBox="0 0 7.828 12.828">
                                        <g id="Group_6" data-name="Group 6"
                                           transform="translate(-92.086 454.914) rotate(-90)">
                                            <line id="Line_4" data-name="Line 4" x2="5" y2="5"
                                                  transform="translate(443.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_5" data-name="Line 5" x1="5" y2="5"
                                                  transform="translate(448.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>

                                </div>


                                <Swiper
                                    Swiper
                                    modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                                    loop={true}
                                    autoplay={false}
                                    // initialSlide={1}
                                    pagination={{
                                        type: "fraction",
                                    }}
                                    navigation={{
                                        prevEl: prevRef.current,
                                        nextEl: nextRef.current,
                                    }}
                                    onBeforeInit={(swiper) => {
                                        swiper.params.navigation.prevEl = prevRef.current;
                                        swiper.params.navigation.nextEl = nextRef.current;
                                    }}
                                    speed={900}
                                >
                                    {
                                        images && images.length > 0 &&
                                        images.map((element, index) => {
                                            return (
                                                <SwiperSlide>
                                                    <div className="spec-with-img__slider__single">
                                                        <Img src={element?.image}/>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </Col>
                            <Col md={{span: 4, offset: 1}}
                                 className={'spec-with-img__content d-flex align-items-center'}>
                                <div className="spec-with-img__content__spec ">
                                    <div>
                                        {title && <h3 className={'split-up'}>{title}</h3>}
                                        {short_desc && <p className={'split-up'}>{short_desc}</p>}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                    :
                    <Container fluid className={'spec-with-img'}
                    >
                        <Row>
                            <Col md={4} className={'spec-with-img__content d-flex align-items-center'}>
                                <div className="spec-with-img__content__spec ">
                                    {title && <h3 className={'split-up'}>{title}</h3>}
                                    {short_desc && <p className={'split-up'}>{short_desc}</p>}
                                </div>
                            </Col>
                            <Col md={{span: 7, offset: 1}} className={'spec-with-img__slider'}>
                                <div className={`${images && images.length <= 1 ? 'opacity' : ''} demo-two__btn__prev`}
                                     ref={prevRef}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                         viewBox="0 0 7.828 12.828">
                                        <g id="Group_6" data-name="Group 6"
                                           transform="translate(-92.086 454.914) rotate(-90)">
                                            <line id="Line_4" data-name="Line 4" x2="5" y2="5"
                                                  transform="translate(443.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_5" data-name="Line 5" x1="5" y2="5"
                                                  transform="translate(448.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                </div>
                                <div className={`${images && images.length <= 1 ? 'opacity' : ''} demo-two__btn__next`}
                                     ref={nextRef}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                         viewBox="0 0 7.828 12.828">
                                        <g id="Group_6" data-name="Group 6"
                                           transform="translate(-92.086 454.914) rotate(-90)">
                                            <line id="Line_4" data-name="Line 4" x2="5" y2="5"
                                                  transform="translate(443.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_5" data-name="Line 5" x1="5" y2="5"
                                                  transform="translate(448.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>

                                </div>

                                <Swiper
                                    Swiper
                                    modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                                    loop={true}
                                    autoplay={false}
                                    // initialSlide={1}
                                    pagination={{
                                        type: "fraction",
                                    }}
                                    navigation={{
                                        prevEl: prevRef.current,
                                        nextEl: nextRef.current,
                                    }}
                                    onBeforeInit={(swiper) => {
                                        swiper.params.navigation.prevEl = prevRef.current;
                                        swiper.params.navigation.nextEl = nextRef.current;
                                    }}
                                    speed={900}
                                >

                                    {
                                        images && images.length > 0 &&
                                        images.map((element, index) => {
                                            return (
                                                <SwiperSlide>
                                                    <div className="spec-with-img__slider__single">
                                                        <Img src={element?.image}/>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </Col>
                        </Row>
                    </Container>
            }
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background-color: #FFFFFF;
  padding-bottom: 200px;
  //.col-md-5{
  //  padding-left: 0px !important;
  //}

  .container-fluid {
    padding-left: ${p => p.offset}px;
  }

  @media (max-width: 767px) {
    .container-fluid {
      padding-left: 0 !important;
    }
  }
  

  .swiper-pagination {
    display: none !important;
  }

  .pent-house-des__top {
    transform: translateY(-50%);

    &__img {
      position: relative;
      padding-top: calc(600 / 998 * 100%);

      &:after {
        content: '';
        position: absolute;
        height: 100%;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 68%, rgba(0, 0, 0, 0.65) 93%);
      }

      h2 {
        position: absolute;
        left: 90px;
        bottom: 50px;
        color: #FFFFFF;
        text-transform: uppercase;
        width: 80%;
        font-size: 35px;
        line-height: 38px;
        z-index: 2;
      }
    }
  }

  .desc-p {
    margin-bottom: 85px;

    h4 {
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 25px;
      color: #ffffff;
      font-weight: bold;
    }

    p {
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;

      &:not(:nth-last-of-type(1)) {
        margin-bottom: 28px;
      }
    }
  }

  .spec-with-img {
    //@media(max-width: 767px){
    //  padding-left: 30px!important;
    //  padding-right: 30px!important;
    //}
    &__content {
      &__top {
        display: flex;
        align-items: center;

        h4 {
          font-size: 48px;
          line-height: 48px;
          text-transform: uppercase;
          color: #ffffff;
          margin-left: 40px;

          span {
            position: relative;
            font-size: 100px;

            margin-right: 12px;
            padding-right: 25px;

            small {
              position: absolute;
              font-size: 25px;
              line-height: 50px;
              right: -10px;
            }
          }
        }
      }

      &__spec {
        //margin-top: 40px;
        h3 {
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          margin-bottom: 20px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          @media (max-width: 767px) {
            margin-bottom: 40px;
          }
        }

        //@media(min-width: 768px) and (max-width: 991px){
        //  p,h3{
        //    margin-left: 15px;
        //    margin-right: 15px;
        //  }
        //}
      }
    }

    &__slider {
      @media (max-width: 767px) {
        padding-right: 0px !important;
      }
      position: relative;
      height: fit-content;

      .slide-number {
        position: absolute;
        bottom: 60px;
        z-index: 3;
        left: 60px;

        p {
          font-size: 36px;
          line-height: 36px;
          color: #F2EEE8;

          span {
            font-size: 20px;
            line-height: 24px;
            color: rgba(242, 238, 232, 0.40);
          }
        }
      }

      &__single {
        padding-top: calc(490 / 668 * 100%);
        position: relative;
        min-height: 100%;

        &:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 38%, rgba(0, 0, 0, 0.7245491946778712) 100%);
        }

        &__content {
          position: absolute;
          bottom: 50px;
          left: 120px;
          z-index: 2;
          display: flex;

          h4 {
            font-size: 24px;
            text-transform: uppercase;
            font-weight: bold;
            line-height: 28px;
            margin-left: 24px;
            color: #f2eee8;
            opacity: 0;
            transition: opacity .3s ease;
          }
        }
      }

      //.demo-two__btn__prev{
      //  display: none;
      //}

      .demo-two__btn__prev {
        position: absolute;
        height: 56px;
        width: 56px;
        border-radius: 100%;
        border: 1px solid #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
        left: 60px;
        top: 0px;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        opacity: 0;
        transition: opacity .3s ease;
        @media (max-width: 767px) {
          left: 30px;
          opacity: 1
        }

        svg {
          transform: rotate(180deg);
        }
      }

      //&:hover .demo-two__btn__prev {
      //  opacity: 1;
      //}

      .demo-two__btn__next {
        position: absolute;
        height: 56px;
        width: 56px;
        border-radius: 100%;
        border: 1px solid #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
        right: 60px;
        top: 0px;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s ease;
        @media (max-width: 767px) {
          right: 30px;

        }
      }

      &:hover .demo-two__btn__next {
        opacity: 1;
      }
    }


    @media (max-width: 767px) {
      .mobile-reverse {
        flex-direction: column-reverse;

      }
    }


  }

  .swiper-slide-active {
    .spec-with-img__slider__single__content {
      h4 {
        opacity: 1
      }
    }
  }

  //@media (max-width: 1024px) {
  //  margin-top: 0 !important;
  //  .pent-house-des__top {
  //    transform: none !important;
  //    min-width: 100%;
  //    margin-left: 0;
  //  }
  //
  //  .desc-p {
  //    margin-top: 60px !important;
  //  }
  //
  //  .spec-with-img__content, .spec-with-img__slider {
  //    min-width: 100%;
  //  }
  //
  //  .pent-house-des__top__img h2 {
  //    left: 70px;
  //  }
  //}

  //@media (max-width: 768px) {
  //  overflow: hidden;
  //  .pent-house-des__top__img h2 {
  //    font-size: 30px;
  //    line-height: 30px;
  //  }
  //
  //  .spec-with-img {
  //    padding-left: 0 !important;
  //  }
  //
  //  .spec-with-img__content {
  //    padding-left: 70px;
  //  }
  //
  //  .spec-with-img__slider .slide-number {
  //    left: 30px;
  //    bottom: 80px;
  //  }
  //
  //  .spec-with-img__slider__single__content {
  //    bottom: 30px;
  //    left: 15px;
  //    right: 15px;
  //
  //    h4 {
  //      margin-left: 0;
  //    }
  //
  //    br {
  //      display: none;
  //    }
  //  }
  //
  //  .spec-with-img__slider {
  //    margin-top: 60px;
  //  }
  //
  //  .spec-with-img__slider .demo-two__btn__next {
  //    opacity: 1;
  //    height: 56px;
  //    width: 56px;
  //    right: 30px;
  //    bottom: 10px;
  //    svg {
  //      height: 20px;
  //    }
  //  }
  //
  //  .demo-two__btn__prev {
  //    opacity: 1;
  //    height: 56px;
  //    width: 56px;
  //    left: 30px;
  //    bottom: 10px;
  //    svg {
  //      height: 20px;
  //    }
  //  }
  //  
  //
  //}

  @media (max-width: 767px) {
    padding-bottom: 120px;
    .pent-house-des__top__img {
      h2 {
        left: 15px;
        right: 15px;
        width: 100%;
        bottom: 40px;
      }
    }

    .spec-with-img__content {
      padding-left: 30px;
      padding-right: 30px;
    }
  }


  @media (max-width: 650px) {
    .spec-with-img__content {
      flex-wrap: wrap;

      h4 {
        font-size: 20px;
        line-height: 24px;
      }

      &__top {
        flex-wrap: wrap;

        img {
          margin-bottom: 40px;
        }

        h4 {
          min-width: 100%;
          margin-left: 0;
          font-size: 36px;
          line-height: 38px;

          span {
            font-size: 60px;
            line-height: 60px;

            small {
              font-size: 20px;
            }
          }
        }
      }

      &__spec {
        ul {
          flex-wrap: wrap;

          li {
            min-width: 100%;
          }
        }
      }
    }

    .spec-with-img__slider__single__content h4 {
      font-size: 20px;
      line-height: 24px;
    }
  }


  .slide-number {
    position: absolute;
    bottom: 60px;
    z-index: 3;
    left: 60px;

    .swiper-pagination-current {
      font-size: 36px;
      line-height: 36px;
      color: #F2EEE8;

      span {
        font-size: 20px;
        line-height: 24px;
        color: rgba(242, 238, 232, 0.40);
      }
    }
  }

  .swiper-pagination {
    font-size: 20px;
    line-height: 20px;
    color: rgba(242, 238, 232, 0.50);
    position: absolute;
    width: fit-content;
    left: 56px;
    bottom: 53px;

    .swiper-pagination-current {
      font-size: 36px;
      line-height: 36px;
      color: #f2eee8;
    }

    @media (max-width: 767px) {
      left: 20px;
      bottom: 80px;
    }
  }

  .opacity, .swiper-button-next, .swiper-button-prev {
    opacity: 0 !important;
  }
`
export default MyComponent;