import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import Button from "../Button";
import {hover} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
const LubeSolution = ({offset, data}) => {

    // api data


    return (
        <StyledLubeSolution className='lube-testing pt-100 divider'>
            <Container fluid className='p-0'>
                <Row>
                    <Col sm={5} className='lube-testing__img'>
                        <div className="lube-testing__img__inner reveal">
                            <Img src={data?.image}/>
                        </div>
                        <div className={` lube-testing__img__box`}
                             style={{left: offset + 30 + 'px'}}>
                            <div className="lube-testing__img__box__inner">
                                {                                        <h2 className={` fade-up`}>{data?.brandkit_title}</h2>
                                }
                                {/*<VisibilitySensor offset={{top: -150}}>*/}
                                {/*    {({isVisible}) =>*/}
                                {/*    }*/}
                                {/*</VisibilitySensor>*/}
                                <Button
                                    download
                                    text={`${data?.button_text}`}
                                    margin={'30px 0 0 0'}
                                    background={`${hover}`}
                                    color={'#FFFFFF'}
                                    hoverBackground={'#FFFFFF'}
                                    hoverColor={'#1961AC'}
                                    border={'1px solid #ffffff'}
                                    file={data?.file}
                                />
                            </div>


                        </div>

                    </Col>

                    <Col sm={{span: 6, offset: 1}} className='lube-testing__text'
                         style={{paddingRight: offset + 30 + 'px'}}>
                        {
                            data?.title && <h4 className={'split-up'}>{data?.title}</h4>

                        }
                        {
                            data?.short_desc &&
                            <div className={'split-up'} style={{width: '100%'}}>
                                {reactHtmlParser(data?.short_desc)}
                            </div>
                        }

                        {/*<VisibilitySensor offset={{top: -150}}>*/}
                        {/*    {({isVisible}) =>*/}
                        {/*        */}
                        {/*    }*/}
                        {/*</VisibilitySensor>*/}
                        {/*<VisibilitySensor offset={{top: -150}}>*/}
                        {/*    {({isVisible}) =>*/}
                        {/*       */}
                        {/*    }*/}
                        {/*</VisibilitySensor>*/}

                    </Col>


                </Row>
            </Container>
        </StyledLubeSolution>
    );
};

const StyledLubeSolution = styled.section`
  background-color: #F6F6F6;
  //padding-top: 100px;
  margin-bottom: 200px;

  .lube-testing__img {
    position: relative;

    &__inner {
      padding-top: calc(420 / 598 * 100%);
      position: relative;
      min-height: 100%;
    }

    &__box {
      width: 50%;
      position: absolute;
      bottom: -50px;
      left: 98px;
      //top: 50%;
      //translate: transform(-50%,-50%);

      &__inner {
        padding-top: calc(224 / 370 * 100%);
        position: relative;
        background-color: ${hover};

        h2 {
          font-size: 28px;
          line-height: 36px;
          color: #ffffff;
          position: absolute;
          top: 30px;
          left: 30px;
          right: 30px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          white-space: normal;
          overflow: hidden;
        }
      }

      .dc-btn {
        position: absolute;
        bottom: 30px;
        left: 30px;
      }
    }
  }

  .lube-testing__text {
    justify-content: center;
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 48px;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1150px) {
    .lube-testing__img__box {
      width: 65%;

      &__inner h2 {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 0px;
    padding-bottom: 75px;
    .col-sm-5, .col-sm-6 {
      min-width: 100%;
      margin: 0;
    }

    .lube-testing__img__box {
      bottom: -150px;
      width: 70%;
    }


    .lube-testing__text {
      padding: 0 30px;
      margin-top: 210px;
    }

  }


`;

export default LubeSolution;
