import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import {BsPlus} from "react-icons/bs";
import {hover} from "../../styles/globalStyleVars";
import Button from "../Button";
import Select, {components} from "react-select";
import MediaSingle from "../MediaSingle";
import ProjectSingle from "../ProjectSingle";
import reactHtmlParser from "react-html-parser";
import 'react-modal-video/css/modal-video.min.css';


const MyComponent = ({data, option, setClick, setClear, selected, setSelected, rawList}) => {

//iscompleted
    const [isCompleted, setIsCompleted] = useState(false)

    const [index, setIndex] = useState(9)

    const initialPosts = data?.slice(0, index);

    const loadMore = () => {

        setIndex(index + 9)
        setClear(false)

        if (index >= data?.length) {
            setIsCompleted(true)
            document.querySelector('.load-more').classList.add('hide');
        } else {
            setIsCompleted(false)
        }
    }

    // console.log(option);
    //Filter the options
    const options = option?.map(element => ({value: element?.name, label: element?.name}));
    let filteredList = selected === '' ? rawList : rawList?.filter(element => element?.type?.[0]?.name === selected?.value);


    const handleStatus = (value) => {
        setSelected(value);
    }
    const [width, setWidth] = useState()

    useEffect(() => {
        setWidth(window.innerWidth)
    }, [])


    //onclick get the option that is selected and make it active
    const getInfo = (index, type) => {
        setClick(type)
        let navLinks = document.querySelectorAll('.inner-menu ul li a');
        for (let j = 0; j <= navLinks.length; j++) {
            if (j === index) {
                navLinks[j]?.classList?.add('active')
            } else navLinks[j]?.classList?.remove('active')
        }
    }


    const customStyles = {
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? `${hover}` : '#221F1F',
            backgroundColor: state.isSelected ? `rgba(232, 231, 231, 0.6)` : '#FFF',
            margin: 0,
            fontSize: 14,
            cursor: 'pointer',
            paddingLeft: 10,
            paddingRight: 10,
            fontWeight: state.isSelected ? 600 : 400,
            "&:hover": {
                backgroundColor: `rgba(232, 231, 231, 0.8)`,
                color: '#FFF',
                cursor: 'pointer'
            },

        }),
        menu: (provided, state) => ({
            ...provided,
            color: '#FFF',
            backgroundColor: state.isSelected ? `${hover}` : 'rgba(255,255,255,0)',
            margin: 0,
            borderRadius: 20,
            fontSize: 14,
            zIndex: 10
            // width: 200,
        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? `${hover}` : '#FFF',
            borderRadius: 0,
            fontWeight: '400',
            color: '#FFF',
            // fontSize: 14,
        }),

    };


    //
    const optionsList = [
        {value: 'all', label: 'All'},
        {value: 'news', label: 'News'},
        {value: 'articles', label: 'Articles'},
        {value: 'blogs', label: 'Blogs'},

    ];
    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#fff" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>
            ;
    };
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };

    return (
        <StyledComponent className={'pb-200 divider'}>
            <Container className='about-section__bottom'>
                <Row>
                    <Col lg={12} className="menus">
                        <div className="drop">
                            <Select
                                classNamePrefix="filter"
                                onChange={handleStatus}
                                isSearchable={false}
                                styles={customStyles}
                                components={{DropdownIndicator}}
                                options={options}
                                placeholder={'All'}
                                defaultValue={options?.[0]?.value}
                            />
                        </div>
                        <div className="inner-menu">
                            <ul className="tab">
                                <li onClick={() => getInfo(0, 'All')}><a className={"active"}>All</a></li>
                                {
                                    options && options?.length > 0 &&
                                    options.map((element, index) => {
                                        return (
                                            <li key={index + 1} onClick={() => getInfo(index + 1, element?.value)}><a
                                                className={""}>{reactHtmlParser(element?.value)}</a></li>
                                        )
                                    })
                                }

                            </ul>
                        </div>
                    </Col>
                    {width > 767 ?
                        initialPosts && initialPosts?.length > 0 &&
                        initialPosts.map((element, index) => {
                            return (
                                <Col lg={4} md={6} key={element.ID}>
                                    <MediaSingle
                                        text={element?.hover_title}
                                        title={element?.post_title}
                                        img={element?.thumb? element?.thumb : '/images/static/nde_media_center_default_thumb.jpg'}
                                        link={`media-center/${element?.post_name}`}
                                        day={element?.date}
                                        month={element?.month}
                                        year={element?.year}
                                        news_link={element?.link}
                                    />
                                </Col>
                            )

                        }) :
                        filteredList && filteredList?.length > 0 &&
                        filteredList.map((element) => {
                            return (
                                <Col md={6} key={element?.ID}>
                                    <MediaSingle
                                        text={element?.hover_title}
                                        title={element?.post_title}
                                        img={element?.thumb? element?.thumb : '/images/static/nde_media_center_default_thumb.jpg'}
                                        link={`media-center/${element?.post_name}`}
                                        day={element?.date}
                                        month={element?.month}
                                        year={element?.year}
                                        news_link={element?.link}

                                    />
                                </Col>
                            )

                        })
                    }

                    {

                    }

                    {data?.length <= 9 ? '' : (
                        <Col md={12} className="load-more" onClick={loadMore}>
                            <Button
                                text={'Load More'}
                                width={'15%'}
                            />
                        </Col>
                    )}

                </Row>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  position: relative;

  .load-more.hide {
    display: none;
  }

  .load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    @media (max-width: 767px) {
      margin-top: 60px;
    }
  }

  .about-section__bottom {
    //margin-top: -50px;
    position: relative;

    @media (max-width: 767px) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    &__single {
      max-width: 100%;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        min-height: 370px;
        margin-bottom: 40px;

      }

      &__inner {
        position: relative;
        padding-top: calc(370 / 370 * 100%);
        overflow: hidden;
        //border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: #E9E9E9;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0%;
          width: 100%;
          transition: .4s cubic-bezier(0.4, 0, 0, 1);
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ECECEC;
            mix-blend-mode: multiply;
            opacity: 1;
            transition: .8s cubic-bezier(0.4, 0, 0, 1);
            transition-delay: .3s;
          }
        }

        .hover-top {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 40px;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
          opacity: 0;
          transform: translateY(-100%);
          z-index: 2;

          h6 {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            color: #404040;
            margin-bottom: 20px;
          }

          ul {
            li {
              position: relative;
              padding-left: 20px;

              &:after {
                position: absolute;
                content: '';
                height: 10px;
                width: 10px;
                background-color: #C1C1C1;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
              }
            }
          }
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        h4 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          color: #FFF;
          z-index: 2;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
        }

        .icon {
          position: absolute;
          top: 30px;
          right: 30px;
          z-index: 1;
          transition: all .8s cubic-bezier(0.4, 0, 0, 1);
          transform: rotate(0deg);

          svg {
            li {
              transition: all .8s cubic-bezier(0.4, 0, 0, 1);

            }
          }

        }

        span {
          position: absolute;
          z-index: 1;
          top: 30px;
          left: 30px;
          font-size: 80px;
          line-height: 48px;
          color: #F9F9F9;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);

        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 100%;
          }

          .icon {
            transform: rotate(-45deg);

            svg {
              line {
                stroke: #061524;
                fill: #061524;
              }
            }
          }

          span {
            top: -10px;
            opacity: 0;
          }


          .hover-top {
            transform: none;
            opacity: 1;
            color: #4F616B;
          }


          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

          h4 {
            color: #061524;
          }

        }

      }
    }
  }

  .inner-menu {
    padding: 0px 0 70px;

    @media (max-width: 767px) {
      display: none;
      padding: 20px 0 !important;
      .col-md-12 {
        padding: 0;
      }
    }

    ul {
      display: inline;
      width: 100%;

      li {
        &:not(:nth-last-of-type(1)) {
          margin-right: 30px;
        }

        margin-bottom: 30px;
        display: inline-block;
        width: auto;

        a {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          color: #1961AC;
          border: 1px solid #1961AC;
          padding: 12px 36px;
          border-radius: 50px;
          height: 40px;
          justify-content: center;
          align-items: center;
          display: flex;
          position: relative;
          cursor: pointer;
          transition: 0.7s all ease !important;


          &.active {
            background: #1961AC;
            color: #F0EDE3;
          }

          &:hover {
            background: #1961AC;
            color: #F0EDE3 !important;
          }

          &.active {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .drop {
    display: none;
  }

  @media (max-width: 767px) {

    .col-lg-12, .col-md-6 {
      padding-left: 0px;
      padding-right: 0px;
    }

    .menus {
      margin-bottom: 60px;

      .drop {
        display: block;
      }
    }
  }

  .css-13cymwt-control {
    border-radius: 20px;
    background-color: ${hover};
  }

  .css-1jqq78o-placeholder {
    margin-left: 12px;
    color: #FFFFFF;
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }

  .css-1s4cg4l-indicatorContainer {
    margin-right: 12px;
  }

  .css-1dimb5e-singleValue {
    color: white;
    margin-left: 12px;
  }

  .filter {

    &__placeholder {
      color: white !important;
      font-size: 16px;
      //font-weight: 500;
      margin-left: 19px;
    }

    &__control {
      //padding-left: 0;
      //padding-right: 0;
      border-radius: 20px;


      &--is-focused {
        background-color: ${hover};
        border: none;
        //border-bottom: 1px solid #FFF;
      }
    }


    &__indicator {
      margin-right: 12px;
    }

    &__value-container {
      //padding-left: 0;
      //padding-right: 0;
      //cursor: pointer !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1550px) {
    .dc-btn {
      width: 18% !important;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .dc-btn {
      width: 25% !important;
    }
  }
  @media (max-width: 767px) {
    .dc-btn {
      width: 40% !important;
    }
  }
  @media (max-width: 400px) {
    .dc-btn {
      width: 48% !important;
    }
  }
`
export default MyComponent;