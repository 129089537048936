import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Accordion} from "react-bootstrap";
import {Img} from "./Img";
import VisibilitySensor from "react-visibility-sensor";
import {BsChevronDown} from "react-icons/bs";
import reactHtmlParser from "react-html-parser";
import {hover, text} from "../styles/globalStyleVars";
import Title from "./Title";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({img,title, id,data}) => {

    return (
        <StyledComponent id={id} className={'footprint pb-200 divider'}>
            <Container className={'footprint-map'}>
                <Row>
                    <Col md={12}>
                        <div className="title">
                            <Title text={title} margin={'0 0 40px 0'}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className={'footprint-map__img'}>
                            <Img src={img}/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className={'footprint-map-mobile'}>
                <Row>
                    <Col md={12}>
                        <div className="title">
                            {
                                data?.title && <Title text={data?.title}/>
                            }

                        </div>
                    </Col>
                </Row>
                <Row className='address__row'>
                    <VisibilitySensor offset={{top: -150}}>
                        {({isVisible}) =>
                            <Col className={` fade-up address__accordion`} sm={12}>
                                <Accordion defaultActiveKey="0">
                                    {
                                        data?.list && data?.list.length>0 &&
                                        data.list.map((element,index)=>{
                                            return(
                                                <Accordion.Item eventKey={index}>
                                                    <Accordion.Header>
                                                        {reactHtmlParser(element?.title)}
                                                        <span><BsChevronDown/></span>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul className={'custom-list'}>
                                                            {ReactHtmlParser(element?.description)}

                                                        </ul>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }
                                </Accordion>
                            </Col>
                        }
                    </VisibilitySensor>
                </Row>
            </Container>


        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background: #F6F6F6;

  .footprint-map {
    &__img {
      position: relative;
      padding-top: calc(680 / 1170 * 100%);
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  .footprint-map-mobile {
    display: none;


    @media (max-width: 767px) {
      display: block;
      .title {
        margin-bottom: 40px;

        h3 {
          font-weight: 500;
          font-size: 60px;
          line-height: 48px;
          color: #4F616B;
        }
      }
    }
  }

  .accordion-item {
    //margin-bottom: 40px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .accordion-header {
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: ${text};
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-transform: capitalize;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(193, 193, 193, 0.5);
      width: 100%;
      text-align: left;
      //margin-bottom: 50px;
      transition: all .4s ease;

      span {
        color: white !important;
      }

      &.collapsed {
        color: ${text};
        border-color: rgba(193, 193, 193, 0.5);
        background: transparent;

        span {
          background-color: transparent;
          color: black !important;


          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: transparent;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 1px solid black;
      color: black;
      background-color: transparent;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: black;
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;

        svg {
          color: white !important;

        }
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);

      }

    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: ${text};
        border-color: rgba(193, 193, 193, 0.5);
      }
    }
  }

  .accordion-body {
    padding-top: 20px;
    padding-bottom: 20px;

    .custom-list {
      padding: 0;

      li {

        position: relative;
        padding-left: 20px;
        //padding-top: 20px;
        &:not(:last-child) {
          padding-bottom: 0px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 12px;
          left: 0;
          margin-top: -5px;
          width: 6px;
          height: 6px;
          box-sizing: content-box;
          background-color: #061524;
        }
      }

    }

    h4 {
      font-size: 16px;
      line-height: 22px;
      color: rgba(34, 31, 31, 0.2);
      margin: 0 0 10px 0;
    }

    p {
      width: 70%;
    }

    p, a {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
    }

    .contact-numbers {
      margin-top: 45px;

      ul {
        li {
          a {
            margin-bottom: 13px;
            display: flex;

            span {
              font-size: 26px;
              font-weight: bold;
              line-height: 32px;
              transition: .3s ease;
            }

            &:hover span {
              color: ${text};
            }
          }

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }
    }

    .map {
      margin-top: 45px;

      iframe {
        width: 100%;
        height: 350px;
      }
    }
  }


  .address__form {

    .form-control {
      font-size: 16px;
      line-height: 22px;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid black;
      border-radius: 0;
      padding-left: 0;
      padding-bottom: 20px;
      margin-bottom: 40px;

      &::placeholder {
        color: rgba(34, 31, 31, 0.5) !important;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
      }
    }

    textarea {
      min-height: 90px;
      max-height: 90px;
      min-width: 100%;
      max-width: 100%;
      margin-bottom: 60px;
    }

    .divider {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .form-control {
        width: calc(50% - 15px);
      }
    }

    &__title {
      margin-bottom: 60px;

      h5 {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
        font-weight: 600;
      }

      p {
        font-size: 26px;
        font-weight: bold;
        line-height: 32px;
      }
    }
  }

  @media (max-width: 768px) {
    .address__accordion {
      min-width: 100%;
    }

    .address__form {
      min-width: 100%;
      margin-top: 60px;
    }
  }

  @media (max-width: 767px) {

    .address__row {
      flex-direction: column-reverse;
    }

    .address__form {
      margin-top: 0;
      margin-bottom: 60px;
    }

    .contact-numbers {
      flex-wrap: wrap;

      .col-sm-4 {
        min-width: 50%;

        &:nth-last-child(1) {
          margin-top: 30px;
          padding-left: 0;
        }
      }
    }
  }
`
export default MyComponent;