import {Img} from "./Img";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import Sticky from "react-stickynode";
import {Link} from "react-scroll";
// import img from '../../public/images/static/hero-banner.jpg'


const InnerBanner = ({title, subtitle, text, img, full, project, id, setClick,  responsibility,mobile_img}) => {

    // ScrollTrigger.refresh();
    // gsap.registerPlugin(ScrollTrigger);

    // offset get
    const [offset, setOffset] = useState(90)
    const [windowWidth, setWindowWidth] = useState(0)
    useEffect(() => {
        setWindowWidth(window.innerWidth)
    }, [windowWidth])

    useEffect(() => {

        setOffset(document.querySelector(' .container').offsetLeft)

        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })


        // Parallax effect
        const tl1 = gsap.timeline({
            scrollTrigger: {
                trigger: "#hero-banner",
                start: "top top",
                end: "bottom top",
                scrub: true,
                invalidateOnRefresh: true,
            }
        });
        gsap.utils.toArray(".InnerBanner__parallax").forEach(layer => {
            const depth = layer.dataset.depth;
            tl1.to(layer, {
                y: (i, el) => -(el?.offsetHeight * el.dataset.depth),
                ease: "none"
            }, 0)
        });
    })

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <StyledInnerBanner id={`${id ? id : 'InnerBanner'}`} full={full}
                           className={`InnerBanner ${project ? 'project_banner' : ''}`}>
            <div id="hero-banner"
                 className="InnerBanner__parallax"

            >
                <div className="blur_image" data-parallax-init={2}>
                    {
                        isMobile ?
                            <>
                                {
                                    mobile_img &&
                                    <div className={'parallax-image'}>
                                        <img src={mobile_img} alt=""/>
                                    </div>
                                }
                                {
                                    !mobile_img &&
                                    <div className={'parallax-image'}>
                                        <img src={img} alt=""/>
                                    </div>
                                }
                            </> :
                            <>
                                {
                                    img &&
                                    <div className={'parallax-image'}>
                                        <img src={img} alt=""/>
                                    </div>
                                }
                            </>

                    }

                    <Container>
                        <Row>
                            <Col md={12}>

                                <Row>
                                    {
                                        responsibility ?
                                            <Col md={8}>
                                                <div className="title">
                                                    {title && <h1 className={'split-up'}>{ReactHtmlParser(title)}</h1>}
                                                </div>
                                            </Col>
                                            :
                                            <Col md={6}>
                                                <div className="title">
                                                    {title && <h1 className={'split-up'}>{ReactHtmlParser(title)}</h1>}
                                                </div>
                                            </Col>
                                    }

                                    <Col md={{span: 4, offset: 2}}>
                                        <div className="text">
                                            {text && <p className={'split-up'}>{text}</p>}
                                        </div>
                                    </Col>
                                </Row>
                                {/*<div className="flex_box ">*/}
                                {/*    */}
                                {/*    */}
                                {/*</div>*/}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  position: relative;
  background-color: #DDD;

  .blur_image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .go-down {
    position: absolute;
    bottom: 60px;
    right: 100px;
    z-index: 99;
    animation: bounce 2s infinite;
    @media (max-width: 767px) {
      display: none;
    }
  }

  @keyframes bounce {
    0% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px)
    }
    50% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px)
    }
    100% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px)
    }
  }

  &.project_banner {
    .title {
      position: relative;
      z-index: 2;

      h1 {
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 28px !important;
        font-family: 'Euclid Square' !important;

      }
    }
  }

  .text {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 767px) {
      margin-top: 20px;
      display: block;
      justify-content: unset;
    }

    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      color: #FFFFFF !important;
      //width: 40%;
      @media (max-width: 767px) {
        width: 100%;
        text-align: left;
        br {
          //display: none;
        }
      }
    }
  }

  .flex_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .title {
      width: 50%;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      .title {
        width: 100%;
      }
    }
  }

  .InnerBanner__parallax {
    padding-top: ${props => props.full ? '100vh' : '100vh'};
    position: relative;
    @media (min-width: 1550px) {
      padding-top: ${props => props.full ? '100vh' : '100vh'};
    }
    @media (max-width: 767px) {
      padding-top: ${props => props.full ? '100vh' : '70vh'} ;
    }

    .container {
      position: absolute;
      margin: 0 auto;
      z-index: 2;
      bottom: 100px;
      left: 0;
      right: 0;

      @media (max-width: 767px) {
        bottom: 60px;
      }

      h1 {
        z-index: 2;
        font-size: 60px;
        line-height: 60px;
        //margin:30px 0 0;
        font-weight: 600;
        color: #FFFFFF;
        //text-transform: uppercase;
        @media (max-width: 767px) {
          font-size: 40px;
          line-height: 40px;
          margin: 20px 0 0;

        }
      }

      h5 {
        color: #FFFFFF;
        z-index: 2;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
      }
    }
  }

  .inner-menu {
    height: 80px;
    background-color: #4F616B;

    @media (max-width: 767px) {
      .col-md-12 {
        padding: 0;
      }
    }

    ul {
      display: flex;
      width: 100%;
      //justify-content: center;

      li {
        &:not(:nth-last-of-type(1)) {
          margin-right: 50px;
        }


        a {
          font-size: 24px;
          line-height: 28px;
          font-weight: 400;
          color: rgba(249, 249, 249, 0.5);
          height: 80px;
          justify-content: center;
          align-items: center;
          display: flex;
          position: relative;
          cursor: pointer;


          &:hover {
            &:after {
              width: 100%;
            }
          }

          &.active {
            color: #D0CA10;
          }

          &.active {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {

    .inner-menu {
      height: 60px;

      ul {
        justify-content: flex-start;
        padding-left: 15px;
        padding-right: 15px;

        li {
          width: 100%;
          margin: 0 !important;

          &:not(:nth-of-type(1)) {
            display: none;
          }

          a {
            width: 100%;
            justify-content: flex-start;
            height: 60px;

            &:after {
              display: none;
            }

            &:nth-of-type(1):after {
              // content: '';
              // border-radius: 50%;
              // background-position: center;
              // height: 24px;
              // width: 24px;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              // background-color: transparent;
              // background-repeat: no-repeat !important;
              // position: absolute;
              // right: 0;
              // left: auto;
              // top: 0;
              // bottom: 0;
              // margin: auto;
            }
          }
        }
      }
    }
  }

  .sticky-inner-wrapper {
    z-index: 9;

    .inner-menu ul li a {
      cursor: pointer;
    }
  }


`;

export default InnerBanner;
