import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Img} from "./Img";
import Title from "./Title";
import {hover} from "../styles/globalStyleVars";

import "lightgallery.js/dist/css/lightgallery.css";
import {
    LightgalleryProvider,
    LightgalleryItem
} from "react-lightgallery";

const Strength = ({title, id, padding, getAfterHeight, bg, img, full, project, nolnk, remove, data, after_bg}) => {
    const info = data?.posts?.list;
    const [getAfter, setgetAfter] = useState(90)
    const [offset, setOffset] = useState(90)
    useEffect(() => {

        // ScrollTrigger.refresh();
        // gsap.registerPlugin(ScrollTrigger);


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])


    const PhotoItem = ({image, group}) => (
        <LightgalleryItem group={group} src={image}>
            <div className="image-wrapper-light-gallery">
                <img alt='' src={image} style={{width: "100%"}}/>
            </div>
        </LightgalleryItem>
    );

    const NewsPrev13 = () => {
        document.querySelector('.swiper-button-prev').click();
    };

    const NewsNext13 = () => {
        document.querySelector('.swiper-button-next').click();
    };

    return (
        <StyledStrength getAfterHeight={getAfter} remove={remove} id={`${id ? id : 'Strength'}`} offset={offset}
                        className={`pionneering parallax-inner-box pb-200 divider`}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="d-flex navigation_wrapper">
                            <div className="title">
                                <Title text={data?.title}/>
                            </div>
                            <ul className="navigation">
                                <li className={'prev_swipper hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                         viewBox="0 0 7.828 12.828">
                                        <g id="Group_6" data-name="Group 6"
                                           transform="translate(1.414 11.414) rotate(-90)">
                                            <line id="Line_4" data-name="Line 4" y1="5" x2="5" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_5" data-name="Line 5" x1="5" y1="5" transform="translate(5)"
                                                  fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>

                                </li>
                                <li className={'next_swipper hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                         viewBox="0 0 7.828 12.828">
                                        <g id="Group_6" data-name="Group 6"
                                           transform="translate(-92.086 454.914) rotate(-90)">
                                            <line id="Line_4" data-name="Line 4" x2="5" y2="5"
                                                  transform="translate(443.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_5" data-name="Line 5" x1="5" y2="5"
                                                  transform="translate(448.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>

                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='about-section__bottom'>
                <Row>
                    <LightgalleryProvider
                        lightgallerySettings={
                            {
                                download: false,
                                // thumbnail: false,
                                fullScreen: true,
                                share: false
                            }
                        }
                    >
                        {
                            data?.list && data?.list?.length > 0 &&

                            <Swiper
                                spaceBetween={30}
                                slidesPerView={4}
                                slideNextClass={'.next'}
                                allowSlideNext={true}
                                slidePrevClass={'.prev'}
                                allowSlidePrev={true}
                                loop={true}
                                // allowTouchMove={true}
                                // autoplay={true}
                                longSwipesMs={900}
                                speed={900}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                }}
                                navigation={{
                                    prevEl: '.prev_swipper',
                                    nextEl: '.next_swipper  ',
                                }}
                                modules={[Navigation]}

                            >
                                {
                                    data?.list && data?.list?.length > 0 &&
                                    data?.list.map((element, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <div>
                                                    <PhotoItem image={element?.image}/>
                                                    <h3>{element?.title}</h3>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>

                        }

                    </LightgalleryProvider>
                </Row>
            </Container>
        </StyledStrength>
    );
};

const StyledStrength = styled.section`

  position: relative;
  overflow: hidden;
  background: #E9E9E9;

  .title_wrapper {
    display: flex;
  }

  //.about-section__bottom-license__img{
  //  position: relative;
  //  padding-top: calc(380/270 *100%);
  //}
  .image-wrapper-light-gallery {
    padding-top: calc(380 / 270 * 100%);
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: pointer;

    img {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .swiper-initialized {
    padding-right: 120px;
    @media (max-width: 767px) {
      padding-right: 0 !important;
      width: 100%;
    }
  }

  .swiper-slide {
    width: 296px !important;
    @media (max-width: 767px) {
      width: 270px !important;
    }

    h3 {
      font-size: 16px;
      line-height: 24px;
      margin-top: 10px;
    }

  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 80px;

    @media (max-width: 767px) {

      margin-bottom: 40px;
    }

    .title {
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #4F616B;
      }
    }

    .d-flex {
      justify-content: space-between;
    }

    .navigation {
      display: flex;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #404040;

        &.hover:after {
          background-color: ${hover};
        }

        &:first-child {
          margin-right: 20px;
        }

        position: relative;
        border-radius: 50%;
        height: 52px;
        width: 52px;

        svg {
          position: relative;

          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0;
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 26px;
            }
          }
        }
      }
    }
  }

  .swiper-wrapper {
    height: auto !important;
  }

  .about-section__bottom {
    //margin-top: -50px;
    padding-left: ${props => props.offset ? props.offset + 30 + 'px' : '90px'};
    position: relative;
    //
    // &:after {
    //   height: 50%;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background: #F6F6F6;
    //   content: "";
      //   display: ${props => props.remove ? 'none' : 'block'};
      //   opacity: ${props => props.remove ? '0' : '1'};
    //   //z-index: -1;
    // }

    @media (max-width: 767px) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }


  }

  @media (max-width: 767px) {
    .navigation_wrapper {
      flex-wrap: wrap;
    }

    .navigation {
      min-width: 100%;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .about-section__bottom__single__inner span {
      top: 55px;
      left: 55px;
    }
  }


`;

export default Strength;