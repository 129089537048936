import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {text} from "../../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({data, id}) => {

    // gsap.registerPlugin(ScrollTrigger);

    const [offset, setOffset] = useState(0)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container')?.offsetLeft)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container')?.offsetLeft)
            }
        })
    }, [])


    return (
        <StyledComponent id={id} className={'ministry divider'}>
            <div className={'ministry__container'}
                 style={{paddingLeft: offset + 30 + 'px'}}>
                <Row>
                    <Col md={{span: 3, offset: 1}} className={''}>
                        <img src={data?.logo} alt=""/>
                        {data?.title && <h3 className={'split-up'}>{data?.title}</h3>}
                    </Col>
                    <Col md={{span: 5, offset: 2}}>
                        <ul className={'customs-list'}>
                            {ReactHtmlParser(data?.description)}
                        </ul>
                    </Col>
                </Row>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #F6F6F6;
  }


  .ministry__container {
    position: relative;
    z-index: 2;

    .customs-list {
      padding: 0;
      counter-reset: listItem;


      li {

        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        position: relative;
        //padding-left: 62px;

        //
        //@media (max-width: 767px) {
        //  padding-left: 78px;
        //}

        &:not(:last-child) {
          border-bottom: 1px solid #ffffff80;
          padding-bottom: 40px;
        }

        &:not(:first-child) {
          padding-top: 40px;

        }

        //&:before {
        //  content: counter(listItem);
        //  counter-increment: listItem;
        //  position: absolute;
        //  top: 55px;
        //  left: 0;
        //  //transform: translate(-50%,-50%);
        //  margin-top: -15px;
        //  width: 10px;
        //  height: 10px;
        //  box-sizing: content-box;
        //  //background-color: #C1C1C1;
        //
        //}
        //
        //&:first-child {
        //  &:before {
        //    top: 15px !important;
        //  }
        //}
      }

    }

    .row {
      background-color: #d0d0d0;
      padding: 120px 0;
    }
  }

  img {
    height: 100px;
  }

  .col-sm-6 {
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 100px;
    }

  }

  h3 {
    font-size: 40px;
    font-weight: 500;
    color: #404040;
    line-height: 48px;
    margin-top: 40px;
  }


  @media (max-width: 767px) {
    h3 {
      font-size: 30px;
      line-height: 30px;
      margin-top: 20px;
      margin-bottom: 60px;
    }

    .ministry__container .customs-list li {
      font-size: 16px;
      line-height: 20px;
    }

    .ministry__container {
      padding-left: 0 !important;

      .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }

      .col-sm-2, .col-sm-7 {
        min-width: 100%;
        margin: 0;
      }

      .col-sm-2 {
        padding: 0 30px !important;
        margin-bottom: 60px;

        img {
          height: 70px;
        }
      }

      .col-sm-6 {
        min-width: 100%;

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 60px;
        }

        h4 {
          font-size: 60px;
          line-height: 60px;
          padding-bottom: 10px;
          margin-bottom: 20px;
          letter-spacing: -4px;

          &:after {
            width: 100%;
          }
        }
      }
    }
  }
`;

export default MyComponent;
