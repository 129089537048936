import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({id,data}) => {

    return (
        <StyledComponent id={id} className={'pb-200 divider'}>
            <>
                <div className={'shadow-behind '}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="749.351" height="341.484" viewBox="0 0 749.351 341.484">
                        <g id="Group_19154" data-name="Group 19154" transform="translate(-6520.604 -7698.5)" opacity="0.5">
                            <path id="Path_1" data-name="Path 1"
                                  d="M377.33,155.114c-2.815-4.5-4.5-7.881-6.755-10.7-32.086-43.908-63.61-87.815-95.7-131.16-3.378-4.5-10.7-7.881-16.325-8.444-6.192,0-12.947,0-19.139-.563h-5.629C188.19,3.689,142.031,3.126,96.434,2H93.057c-6.755.563-10.133,3.378-13.51,9.57-24.768,48.411-50.1,96.259-74.305,144.107-2.252,5.066-2.815,12.947-.563,18.013,23.08,48.974,46.159,97.948,69.8,146.359a123.108,123.108,0,0,0,6.192,10.7c2.815-5.066,5.066-9.007,7.318-12.947C112.2,271.075,145.971,207.465,170.74,160.18c2.815-5.629,5.629-9.57,13.51-9.57,6.192,0,11.821.563,18.013.563a4.255,4.255,0,0,0,2.252.563c19.7.563,38.841.563,58.543,1.126,21.954.563,44.471,1.126,66.424,1.126h0c11.258,0,22.517.563,33.212.563a108.87,108.87,0,0,0,14.636.563"
                                  transform="translate(6517.861 7708.256)" fill="none" stroke="#c1c1c1" stroke-width="1"/>
                            <path id="Path_2" data-name="Path 2"
                                  d="M443.83,152.551c-2.815-4.5-4.5-7.881-6.755-10.7-32.086-43.908-63.61-87.815-95.7-131.16-3.377-4.5-10.7-7.881-16.325-8.444-6.192,0-12.947,0-19.139-.563h-5.629c-45.6,0-91.193-.563-137.352-1.689h-3.378c-6.755.563-10.133,3.378-13.51,9.57-24.768,48.411-50.1,96.259-74.305,144.107-2.252,5.066-2.814,12.947-.563,18.013,23.08,48.974,46.159,97.948,69.8,146.359a123.115,123.115,0,0,0,6.192,10.7c2.815-5.066,5.066-9.007,7.318-12.947,24.205-46.722,57.981-110.332,82.749-157.617,2.815-5.629,5.629-9.57,13.51-9.57,6.192,0,11.821.563,18.013.563a4.256,4.256,0,0,0,2.252.563c19.7.563,38.841.563,58.543,1.126,21.954.563,44.471,1.126,66.424,1.126h0c11.258,0,22.517.563,33.212.563,4.5.563,9.007,0,14.636,0"
                                  transform="translate(6825.701 7699)" fill="none" stroke="#c1c1c1" stroke-width="1"/>
                        </g>
                    </svg>
                </div>
                <Container>
                    <Row>
                        <Col md={12}>
                            {data?.title &&
                                <h3 className={'split-up'}>
                                    {ReactHtmlParser(data?.title)}
                                </h3>
                            }

                        </Col>
                    </Row>
                </Container>
            </>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background: #FFFFFF;
  position: relative;
  overflow: hidden;

  padding-top: 100px;
  .shadow-behind {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    svg {
      width: 90%;
    }
  }

  h3 {
    font-size: 80px;
    line-height: 80px;
    font-weight: 500;

    span {
      color: #1961AC;
    }

    @media (max-width: 767px) {
      font-size: 40px;
      line-height: 40px;
    }
  }
  @media(max-width: 767px){
    .shadow-behind {
       svg {
        width: 80%;
      }
    }
  }

`
export default MyComponent;