import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import {hover} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {gsap, TweenMax} from "gsap";
import {useSelector} from "react-redux";

const MyComponent = ({data}) => {
    let getPost = useSelector(state => state.home)
    useEffect(() => {
        // mouse hover test
        let getHoverElement = document.querySelector('.home-banner__wrap__title h1')

        // initial test
        if (!getPost?.loading) {
            setTimeout(() => {
                var $this = getHoverElement;
                var relX = window.innerWidth - getHoverElement.offsetLeft;
                var relY = window.innerHeight - getHoverElement.offsetTop;

                var posX = (relX - getHoverElement.clientHeight / 2) / getHoverElement.clientWidth * 200;
                var posY = (relY - getHoverElement.clientHeight / 2) / getHoverElement.clientHeight * 200;

                // console.log('posX', posX, 'posY', posY)
                gsap.to(getHoverElement, {
                    backgroundPosition: '200px 200px',
                    duration: 4.5
                })
            }, 600)
        }

        // create the test function
        function parallaxIt(e, target, movement) {
            var $this = getHoverElement;
            var relX = e.pageX - $this.offsetLeft;
            var relY = e.pageY - $this.offsetTop;

            var posX = (relX - $this.clientHeight / 2) / $this.clientWidth * movement;
            var posY = (relY - $this.clientHeight / 2) / $this.clientHeight * movement;

            TweenMax.to(target, 6.5, {
                backgroundPosition: posX + "px " + posY + "px",
                ease: 'linear'
            });
        }

        // on hover call the test function
        getHoverElement.addEventListener('mousemove', function (e) {
            parallaxIt(e, getHoverElement, 200);
        });

    }, [])

    return (

        <StyledComponent className={'home-banner parallax'}>
            <Img parallax src={data?.image}/>

            <Container>
                <Col md={8} sm={12} className={'p-0'}>
                    <div className="home-banner__wrap">
                        <div className="home-banner__wrap__title">
                            {/*{data?.subtitle && <h1 className={'split-up'}>{data?.subtitle}</h1>}*/}
                            <h1>Transformation <br/>
                                Reimagined</h1>
                        </div>

                        <div className="home-banner__wrap__content">
                            {data?.title && <p className={'split-up'}>{reactHtmlParser(data?.title)}</p>}
                        </div>
                    </div>
                </Col>

                <div className="go-down hover">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.828" height="7.828" viewBox="0 0 12.828 7.828">
                        <g id="Group_6" data-name="Group 6" transform="translate(-442.086 -92.086)">
                            <line id="Line_4" data-name="Line 4" x2="5" y2="5" transform="translate(443.5 93.5)"
                                  fill="none" stroke="#3b3838" stroke-linecap="round" stroke-width="2"/>
                            <line id="Line_5" data-name="Line 5" x1="5" y2="5" transform="translate(448.5 93.5)"
                                  fill="none" stroke="#3b3838" stroke-linecap="round" stroke-width="2"/>
                        </g>
                    </svg>

                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  height: calc(100vh + 200px);
  position: relative;

  .container {
    position: relative;
    z-index: 2;
    height: 100vh;
    display: flex;
    align-items: flex-end;
  }

  .home-banner__wrap__title {
    width: 100%;
    position: relative;
    margin-bottom: 80px;

    h1 {
      font-size: 100px;
      font-weight: bold;
      color: #ffffff;
      line-height: 110px;
      letter-spacing: -3px;
      background-image: url('/images/static/beta-1.jpg');
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      //background-position: center;
      //transition: background-position 5s ease;
    }
  }

  .home-banner__wrap__content {
    //width: 70%;
    padding-bottom: 40px;

    p {
      font-size: 20px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .go-down {
    position: absolute;
    height: 56px;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    right: 15px;
    bottom: 40px;
    cursor: pointer;

    svg {
      animation: bounceing .8s;
      animation-direction: alternate;
      animation-iteration-count: infinite;

      line {
        transition: .4s ease;
      }
    }

    &:after {
      background-color: ${hover};
    }

    &:hover {
      svg {
        line {
          stroke: #ffffff;
        }
      }
    }
  }

  @keyframes bounceing {
    0% {
      transform: translate3d(0, -1px, 0);
    }
    100% {
      transform: translate3d(0, 6px, 0);
    }
  }

  @media (max-width: 991px) {
    .home-banner__wrap__title h1 {
      font-size: 50px;
      line-height: 56px;
      font-weight: 900;
    }
  }

  @media (max-width: 767px) {
    background-color: #061524;
    height: auto;
    img {
      height: 100vh;
    }

    .container {
      height: auto;
    }

    .home-banner__wrap__title {
      height: 100vh;

      h1 {
        position: absolute;
        bottom: 230px;
        letter-spacing: -2px;
      }
    }

    .home-banner__wrap__content {
      width: 100%;
    }

    .go-down {
      top: calc(100vh - 175px);
      bottom: auto;
      right: auto;
      left: 15px
    }

  }

  @media (max-width: 380px) {
    .home-banner__wrap__title h1 {
      font-size: 46px;
      line-height: 52px;
    }
  }

  @media (max-width: 350px) {
    .home-banner__wrap__title h1 {
      font-size: 42px;
      line-height: 50px;
    }
  }

`;

export default MyComponent;
