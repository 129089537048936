import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Title from "../Title";
import ProjectSingle from "../ProjectSingle";
import Button from "../Button";

const MyComponent = ({data}) => {
    let filteredProject = data && data?.filter(element => element?.isfeatured === 'on')

    return (
        <StyledComponent className={'feature-projects pt-200 pb-200'}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <Title margin={'0 0 80px 0'} marginMobile={'0 0 40px 0'}
                               text={'Notable <span>projects</span>'}/>
                    </Col>
                </Row>
                <Row>
                    {
                        filteredProject && filteredProject?.length > 0 &&
                        filteredProject.slice(0, 4).map((element) => {
                            return (
                                <Col sm={6} key={element?.ID}>
                                    <ProjectSingle img={element?.thumb} title={element?.title}
                                                   desc={element?.post_title}
                                                   link={`project/${element?.post_name}`}/>
                                </Col>
                            )
                        })
                    }
                </Row>
                <Row>
                    <Col sm={12}>
                        <Button src={'/projects'} width={'fit-content'} text={'View Projects'}/>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F6F6F6;
  @media (max-width: 650px) {
    .col-sm-6 {
      min-width: 100%;
    }
  }
`;

export default MyComponent;
