import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "./Img";
import ReactHtmlParser from "react-html-parser";

const MyComponent = ({description, careerPage, data, id}) => {


    return (
        <StyledComponent className={'career-global pb-200 divider'} id={id}>
            {/*<Img parallax src={data?.bg_image ? data?.bg_image : data?.image}/>*/}
            <Container>
                <Row>
                    <Col sm={careerPage ? 11 : 9}>
                        {data?.title &&
                            <h4 className={'split-up'}>{ReactHtmlParser(data?.title)}</h4>
                        }
                        {
                            data?.subtitle &&
                            <h4 className={'split-up'}>{ReactHtmlParser(data?.subtitle)}</h4>
                        }
                        {data?.description && ReactHtmlParser(data?.description)}
                        {/*<div className={'split-up'}>*/}
                        {/*    */}
                        {/*</div>*/}
                    </Col>
                    
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  background-color: #061524;
  padding-top: 150px;

  .global-image {
    background-image: none !important;
  }

  h4 {
    font-size: 40px;
    line-height: 48px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 50px;
  }

  p {
    color: rgba(255, 255, 255, 0.8);

    &:not(:nth-last-of-type(1)) {
      margin-bottom: 24px;
    }
  }


  @media (max-width: 768px) {
    .col-sm-9 {
      min-width: 100%;
    }
  }

  @media (max-width: 767px) {
    padding-top: 90px !important;

    h4 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 40px;
    }

    h4 p {
      font-size: 30px !important;
      line-height: 30px !important;
    }
  }


`;

export default MyComponent;
