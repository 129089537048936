import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {hover} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {

    // gsap.registerPlugin(ScrollTrigger);

    const [offset, setOffset] = useState(0)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container')?.offsetLeft)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container')?.offsetLeft)
            }
        })
    }, [])


    // counter
    useEffect(() => {
        const items = document.querySelectorAll(".count-it");
        gsap.from(items, {
            scrollTrigger: {
                trigger: ".counter",
                toggleActions: 'play none none reverse',
            },
            textContent: 0,
            duration: 1.5,
            ease: "Power3.easeInOut",
            snap: {textContent: 1},

        });
    }, [data])

    return (
        <StyledComponent className={'counter divider'}>
            <div className={'counter__container'}
                 style={{paddingLeft: offset + 30 + 'px'}}>
                <Row>
                    <Col sm={{span: 2, offset: 1}} className={'p-0'}>
                        <img src="/images/static/logo-white-v2.svg" alt=""/>
                    </Col>

                    <Col className={'d-flex flex-wrap'} sm={{span: 7, offset: 1}}>
                        {
                            data?.list && data?.list?.length > 0 &&
                            data?.list.map((element, index) => {
                                return (
                                    <Col sm={6} key={index}>
                                        <h4><span className={'count-it'}>{element?.title}</span>+</h4>
                                        <p>{element?.subtitle}</p>
                                    </Col>
                                );
                            })
                        }

                    </Col>
                </Row>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  margin-top: -5px;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 50%;
    top: 0;
    background-color: #061524;
  }

  .counter__container {
    position: relative;
    z-index: 2;

    .row {
      background-color: ${hover};
      padding: 120px 0;
    }
  }

  img {
    height: 50px;
  }

  .col-sm-6 {
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 100px;
    }

  }

  h4 {
    font-size: 80px;
    font-weight: 300;
    color: #FFF;
    letter-spacing: -5px;
    line-height: 80px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 85%;
      left: 0;
      right: 0;
      background-color: rgba(193, 193, 193, 0.5);
    }
  }

  p {
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
    max-width: 80%;
    line-height: 28px;
  }

  @media (max-width: 767px) {
    .counter__container {
      padding-left: 0 !important;

      .col-sm-2, .col-sm-7 {
        min-width: 100%;
        margin: 0;
      }

      .col-sm-2 {
        padding: 0 30px !important;
        margin-bottom: 60px;

        img {
          height: 70px;
        }
      }

      .col-sm-6 {
        min-width: 100%;

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 60px;
        }

        h4 {
          font-size: 60px;
          line-height: 60px;
          padding-bottom: 10px;
          margin-bottom: 20px;
          letter-spacing: -4px;

          &:after {
            width: 100%;
          }
        }

        p {
          min-width: 100%;
        }
      }
    }
  }
`;

export default MyComponent;
