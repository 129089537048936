import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Table} from "react-bootstrap";
import {
    InstagramShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton, InstapaperIcon
} from "react-share";
import Sticky from 'react-stickynode';
import {hover, text} from "../../styles/globalStyleVars";
import Gallery from "./Gallery";
import Banner from "../Projects/Banner";
import reactHtmlParser from "react-html-parser";
import ReactHtmlParser from "react-html-parser";


const MyComponent = ({data}) => {


    //Refactor Data

    const banner=data?.[0]?.banner;
    const state=data?.[0]?.state;
    const gallery=data?.[0]?.gallery;
    const conclusion=data?.[0]?.conclusion;
    const description=data?.[0]?.description?.text;



    const [shareUrl, setShareUrl] = useState('')
    useEffect(() => {
        setShareUrl(window.location.href)
    }, [])

    const [width, setWidth] = useState(0)
    useEffect(() => {
        setWidth(window.innerWidth)
    }, [])



    return (
        <StyledComponent className={'detail-page'}>
            {/*<div className={'detail-page__img'}>*/}
            {/*    <Img src={'/images/dynamic/project-detail.jpg'}/>*/}
            {/*</div>*/}
            <Banner img={banner?.image} title={'Talent Hunt & Player Management System\n' +
                'Development for BKSP'}/>
            <Container className={'detail-page__text-content'}>
                <Row>
                    <Col sm={2} className={'detail-page__text-content__share'}>
                        <Sticky top={100} style={{ maxHeight: '100px', overflow: 'auto' }}>
                            <p>Share:</p>
                            <ul>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4.877" height="10.466"
                                         viewBox="0 0 4.877 10.466">
                                        <path id="Path_26" data-name="Path 26"
                                              d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                              transform="translate(-1009.116 -3914.995)" fill="#fff"/>
                                    </svg>
                                    <FacebookShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.997" height="9.75"
                                         viewBox="0 0 11.997 9.75">
                                        <path id="Path_5915" data-name="Path 5915"
                                              d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106"
                                              transform="translate(-449.716 -10.375)" fill="#fff"/>
                                    </svg>
                                    <TwitterShareButton url={shareUrl}/>
                                </li>

                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.063" height="8.561"
                                         viewBox="0 0 9.063 8.561">
                                        <path id="LinkedIn"
                                              d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                              transform="translate(-1087.435 -3916.306)" fill="#fff"/>
                                    </svg>
                                    <LinkedinShareButton url={shareUrl}/>
                                    {/*<InstapaperIcon url={shareUrl}/>*/}
                                </li>
                            </ul>
                        </Sticky>
                        <div className={'mobile-share'}>
                            <p>Share:</p>
                            <ul>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="4.877" height="10.466"
                                         viewBox="0 0 4.877 10.466">
                                        <path id="Path_26" data-name="Path 26"
                                              d="M1012.361,3918.425v-.9a1.965,1.965,0,0,1,.019-.315.612.612,0,0,1,.086-.219.351.351,0,0,1,.216-.149,1.551,1.551,0,0,1,.4-.041h.9V3915h-1.441a2.344,2.344,0,0,0-1.8.594,2.5,2.5,0,0,0-.546,1.75v1.086h-1.08v1.8h1.079v5.233h2.166v-5.233h1.442l.19-1.8Z"
                                              transform="translate(-1009.116 -3914.995)" fill="#fff"/>
                                    </svg>
                                    <FacebookShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11.997" height="9.75"
                                         viewBox="0 0 11.997 9.75">
                                        <path id="Path_5915" data-name="Path 5915"
                                              d="M453.489,20.125a6.955,6.955,0,0,0,7-7q0-.16-.007-.318a5.011,5.011,0,0,0,1.228-1.274,4.912,4.912,0,0,1-1.413.387,2.47,2.47,0,0,0,1.082-1.362,4.933,4.933,0,0,1-1.563.6,2.464,2.464,0,0,0-4.195,2.245,6.988,6.988,0,0,1-5.073-2.571,2.463,2.463,0,0,0,.762,3.286A2.444,2.444,0,0,1,450.2,13.8c0,.01,0,.021,0,.031a2.462,2.462,0,0,0,1.975,2.413,2.459,2.459,0,0,1-1.111.042,2.464,2.464,0,0,0,2.3,1.71,4.938,4.938,0,0,1-3.057,1.054,5,5,0,0,1-.587-.034,6.968,6.968,0,0,0,3.773,1.106"
                                              transform="translate(-449.716 -10.375)" fill="#fff"/>
                                    </svg>
                                    <TwitterShareButton url={shareUrl}/>
                                </li>
                                <li className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.063" height="8.561"
                                         viewBox="0 0 9.063 8.561">
                                        <path id="LinkedIn"
                                              d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"
                                              transform="translate(-1087.435 -3916.306)" fill="#fff"/>
                                    </svg>
                                    <LinkedinShareButton url={shareUrl}/>
                                    {/*<InstapaperIcon url={shareUrl}/>*/}
                                </li>
                            </ul>
                        </div>

                    </Col>

                    <Col sm={10} className={'detail-page__text-content__detail'}>
                        <div className={'full-detail'}>
                            <div className={'detail-top'}>

                                <div className={'detail-page__text-content__detail__date '}>
                                    <p>Client</p>
                                    <p>Rajshahi City Corporation</p>
                                </div>
                                <div className={'detail-page__text-content__detail__date '}>
                                    <p>Domain</p>
                                    <p>Citizen’s Services</p>
                                </div>
                                <div className={'detail-page__text-content__detail__date '}>
                                    <p>Status</p>
                                    <p>November 2023</p>
                                </div>

                                {/*{*/}
                                {/*    state?.list && state?.list?.length>0 &&*/}
                                {/*    state?.list?.map((element,index)=>{*/}
                                {/*        return(*/}
                                {/*            <div className={'detail-page__text-content__detail__date '}>*/}
                                {/*                <p>{reactHtmlParser(element?.title)}</p>*/}
                                {/*                <p>{element?.short_desc}</p>*/}
                                {/*            </div>*/}
                                {/*        )*/}
                                {/*    })*/}
                                {/*}*/}
                            </div>
                            <h2>Design, supply and implementation of:</h2>
                            <ul className={'custom-list'}>
                                <li>Event Circulation Management System</li>
                                <li>Player Registration Management System</li>
                                <li>Election Process Management System</li>
                                <li>Enrollment Management System</li>
                                <li>Training Management System</li>
                                <li>Accounts Management System</li>
                                <li>Assessment Management System</li>
                                <li>Result Publication Management System</li>
                                <li>Game Management System</li>
                                <li>Talent Bank Management</li>
                            </ul>
                            {/*{reactHtmlParser(description)}*/}
                            {/*<Gallery list={gallery?.list} title={gallery?.title}/>*/}
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*<h2>{conclusion?.title}</h2>*/}
                            {/*<p>{conclusion?.description}</p>*/}
                            {/*<br/>*/}
                            {/*<br/>*/}

                        </div>

                    </Col>
                </Row>
            </Container>


        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  margin-bottom: 160px;

  .full-detail {

    border-bottom: 1px solid ${text};
  }

  .detail-page__img {
    position: relative;
    padding-top: calc(612 / 1366 * 100%);
    @media (max-width: 767px) {
      padding-top: calc(560 / 375 * 100%);
    }
  }
  @media(max-width: 767px){
    .title h2{
      text-align: start !important;
    }
  }
  .detail-page__heading {
    h1 {
      font-size: 32px;
      line-height: 36px;
      color: #ED5333;
      font-weight: 600;
    }

    &__tags {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      p {
        color: ${hover};
        font-size: 16px;
        line-height: 24px;
      }

      ul {
        display: flex;

        li {

          font-size: 16px;
          line-height: 24px;
          color: ${hover};


          &:not(:nth-last-child(1)) {
            padding-right: 20px;
            margin-right: 20px;
            border-right: 0;
            border-right: 1px solid #CED7ED;
          }
        }
      }
    }

  }

  .detail-page__banner {
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .hover:after {
    border-radius: 50%;
  }

  .detail-page__text-content {
    padding-top: 40px;

    &__share {
      .mobile-share {
        display: none;
      }

      @media (max-width: 767px) {
        //border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
        .mobile-share {
          display: flex;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px solid rgba(217, 217, 217, 0.5) !important;
        }
        .sticky-outer-wrapper {
          display: none;
        }
      }

      p {
        color: #1961AC;
        margin-bottom: 20px;
      }

      ul {
        li {
          min-height: 30px;
          height: 30px;
          width: 30px;
          min-width: 30px;
          border-radius: 50%;
          background-color: #1961AC;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-bottom: 20px;
          position: relative;

          button {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 2;
          }

          svg {
            position: relative;
            z-index: 2;
          }
        }
      }

      @media (max-width: 767px) {
        display: flex;
        justify-content: space-between !important;
      }
    }

    &__detail {
      .custom-list {
        padding: 0;
        

        li {

          position: relative;
          padding-left: 20px;
          //padding-top: 20px;
          &:not(:last-child) {
            padding-bottom: 0px;
          }

          &:before {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            margin-top: -5px;
            width: 6px;
            height: 6px;
            box-sizing: content-box;
            background-color: #061524;
          }
        }

      }

      img {
        width: 100%;
      }

      span {
        p {
          color: ${hover};
          font-weight: 500;
          margin-top: 20px;
        }
      }

      .detail-top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 60px;
        border-bottom: 1px solid rgba(217, 217, 217, 0.5);
        margin-bottom: 60px;
        @media (max-width: 767px) {
          display: block;
        }
      }


      &__date {
        //display: flex;
        //justify-content: space-between;
        p {
          font-weight: 500 !important;
          font-size: 16px;
          line-height: 24px;
          color: #3B3838;

          &:first-child {
            font-weight: 400 !important;
            font-size: 12px;
            line-height: 24px;
            margin-bottom: 10px !important;
            color: #3B3838;
            opacity: 0.5;
          }
          @media(max-width: 767px){
            &:not(last-child){
              margin-bottom: 40px;
            }
          }
        }

      }

      h1 {
        font-size: 40px;
        line-height: 48px;
        font-weight: 500;
        margin-bottom: 120px;
        @media (max-width: 767px) {
          font-size: 32px;
          line-height: 40px;
          margin-top: 40px;
        }
      }

      h2 {
        font-size: 28px;
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      p {
        font-weight: 400;
      }

      .quotes {
        padding-left: 40px;
        position: relative;
        margin-bottom: 40px;

        &:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 100%;
          left: 0;
          top: 0;
          background-color: ${hover};
          border-radius: 5px;
        }

        h6 {
          margin-top: 30px;
          font-weight: 500;
          color: ${text};
        }
      }

      table {
        min-width: 100%;
        margin-bottom: 30px;
        tr{
          td{
            &:first-child {
              margin-right: 30px;
            }
          }
        }


        th {
          color: ${hover};
          border-bottom: 1px solid ${text};
          padding: 20px 0;
        }

        td {
          padding: 20px 0;
          border-bottom: 1px solid rgba(217, 217, 217, 0.5);


          &:nth-of-type(1) {
            padding-right: 30px;
          }
        }
      }

      .custom-list {
        padding-bottom: 60px;

        li {

          position: relative;
          padding-left: 20px;
          :first-child{
            &:before {
              top: -6px;

            }
          }
          &:not(:first-child) {
            padding-top: 20px;

          }
          &:not(:last-child) {
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(217, 217, 217, 0.5);
          }

          &:before {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            margin-top: 13px;
            width: 6px;
            height: 6px;
            box-sizing: content-box;
            background-color: #061524;
          }
        }

      }

      .video-box {
        position: relative;
        cursor: pointer;
        margin-top: 30px;
        width: 100%;
        margin-bottom: 60px;

        img {
          width: 100%;
        }

        .hover-btn {
          height: 80px;
          width: 80px;
          background-color: ${hover};
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 2;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 0;
          right: 0;

          &.hover:after {
            border-radius: 50%;
          }

          svg {
            position: relative;
            z-index: 2;
          }

          path {
            transition: all .3s ease;
          }

        }

        &:hover {

          .hover-btn {
            &:after {
              height: 100%;
              width: 100%;
            }

            path {
              fill: #FFF;
            }
          }
        }
      }


    }

  }


  @media (max-width: 767px) {

    .detail-top {
      display: block;
    }

    margin-bottom: 80px;
    .detail-page__heading__tags {
      flex-wrap: wrap;

      ul {
        min-width: 100%;
        margin-bottom: 20px;
      }

      p {
        min-width: 100%;
      }
    }

    .detail-page__text-content__share {
      margin-bottom: 60px;
      min-width: 100%;

      ul {
        display: flex;

        li {
          margin-right: 10px;
        }
      }
    }

    .detail-page__text-content__detail {
      min-width: 100%;
    }
  }
  //.sticky-inner-wrapper{
  //  top:120px !important;
  //}

`;

export default MyComponent;
