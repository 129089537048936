import styled from "styled-components";
import {Container, Row, Col, Form, Modal} from "react-bootstrap";
import {hover} from '../styles/globalStyleVars'
import {Img} from "./Img";
import Button from "./Button";
import React, {useEffect, useState} from "react";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {postForm} from "../api/redux/Contact";

const SpecialtyLube = ({data, id, handleInitSmoother, handleKillSmoother, detail}) => {
    gsap.registerPlugin(CSSPlugin);

    //form  functionality
    const [state, setState] = useState(false);
    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);


    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });


    const onSubmit = (e) => {

        let api_services = apiEndPoints.HOME_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('your-message', e?.message);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            setState(true)
            success('Successfully Submitted')
            reset();
        }
    };


    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])


    // modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <StyledLube id={id} className='specialty-lube'>

                {data && data?.image ?
                    <div className="parallax-img">
                        <Img src={data?.image && data?.image} alt=""/>
                    </div> :
                    <div className="parallax-img">
                        <Img src={'/images/dynamic/footer-top.jpg'} alt=""/>
                    </div>
                }


                <Container>
                    <Row>
                        <Col md={8} sm={12}>
                            <h2>{data?.title ? data?.title : 'We create human-centric digital products.'}</h2>
                        </Col>

                        <div className='bottom-text-wrap'>
                            <Col sm={4}>
                                <div className="bottom-text-wrap__box">
                                    <h4>Want to explore opportunities?</h4>
                                    {
                                        detail ?
                                            <Button border={'1px solid #FFF'}
                                                    hoverBackground={'#FFF'}
                                                    hoverColor={hover}
                                                    background={'transparent'}
                                                    color={'#FFF'}
                                                    text={'Explore'}
                                                    src={'/projects'}
                                            /> :
                                            <Button onClick={handleShow} border={'1px solid #FFF'}
                                                    hoverBackground={'#FFF'}
                                                    hoverColor={hover}
                                                    background={'transparent'}
                                                    color={'#FFF'}
                                                    text={'Get in touch'}/>
                                    }

                                </div>
                            </Col>
                        </div>
                    </Row>
                </Container>


            </StyledLube>


            {/*popup*/}
            <Modal className={'get-touched'} show={show} onHide={handleClose}>
                <div className="slide-form">
                    <h4>Get in Touch!</h4>
                    <Form>
                        <Form.Group>
                            <Form.Control
                                className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("name", {
                                    required: {
                                        value: true,
                                        message: 'please enter your username first'
                                    }
                                })}
                                type="text"
                                placeholder="Name *"/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("email", {
                                    required: {
                                        value: true,
                                        message: 'please enter your email'
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'please enter a valid email address'
                                    }
                                })}
                                type="email"
                                placeholder="Email*"/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("phone", {
                                    required: {
                                        value: true,
                                        message: 'please enter your phone first'
                                    },
                                    // pattern: {
                                    //     value: /^01[0-9]{9}$/,
                                    //     message: 'please enter a valid 11 digit phone number'
                                    // }
                                })}
                                type="number"
                                placeholder="Phone Number*"/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("message", {
                                    required: {
                                        // value:true,
                                        message: 'please enter your email'
                                    },

                                })}
                                type="text"
                                placeholder="Message (Optional)"/>
                        </Form.Group>

                        <div className="close-btn hover" onClick={handleClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10.829" height="10.828"
                                 viewBox="0 0 10.829 10.828">
                                <g id="Group_20272" data-name="Group 20272" transform="translate(-3166.586 -119.586)">
                                    <g id="Group_18979" data-name="Group 18979" transform="translate(1 -1.5)">
                                        <line id="Line_4" data-name="Line 4" x2="8" y2="8"
                                              transform="translate(3167 122.5)"
                                              fill="none" stroke="#3b3838" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_3877" data-name="Line 3877" x1="8" y2="8"
                                              transform="translate(3167 122.5)" fill="none" stroke="#3b3838"
                                              stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </g>
                            </svg>

                        </div>
                        <div onClick={handleSubmit(onSubmit, onError)}>
                            <Button margin={'60px 0 0 0'} color={'#404040'} background={'transparent'}
                                    border={'1px solid #404040'}
                                    text={'Submit Message'}/>
                        </div>

                    </Form>
                </div>
            </Modal>
        </>

    );
};
const StyledLube = styled.section`
  position: relative;
  padding-top: 180px;
  padding-bottom: 180px;
  margin-bottom: 180px;

  h2 {
    color: #ffffff;
    margin: 0 0 60px 0;
    font-size: 60px;
    font-weight: 500;
    line-height: 68px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    margin: 0;
    color: #ffffff;
  }

  .bottom-text-wrap {
    position: relative;
    width: 100%;

    .col-sm-4 {
      position: absolute;
      bottom: -180px;
      transform: translateY(50%);
    }

    &__box {
      background-color: ${hover};
      padding: 40px;

      h4 {
        color: #ffffff;
        margin: 0;
        font-size: 28px;
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 40px;
      }
    }
  }

  @media (max-width: 1150px) {
    .bottom-text-wrap__box {
      h4 {
        font-size: 25px;
        line-height: 28px;
      }
    }
  }

  @media (max-width: 1020px) {
    margin-bottom: 140px;
    overflow: visible;
    .container {
      overflow: visible;
    }

    .bottom-text-wrap {
      .col-sm-6 {
        min-width: 100% !important;
        margin: 0;

        p {
          margin-bottom: 40px;
        }
      }

    }
  }

  @media (max-width: 991px) {
    .bottom-text-wrap {
      .col-sm-4 {
        min-width: 50%;
      }
    }
  }

  @media (max-width: 600px) {
    padding-top: 120px;
    h2 {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 20px;
    }

    .col-sm-4 {
      padding: 0;

      .bottom-text-wrap__box {
        padding: 40px 15px;
      }
    }

  }


  .form-overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
    opacity: 0;
    display: none;
  }




`;
export default SpecialtyLube;
