import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import BannerTextAnimated from "../../components/home/BannerTextAnimated";
import Banner from "../../components/home/Banner";

const MyComponent = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Animation test</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <BannerTextAnimated/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
