import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import ReactHtmlParser from "react-html-parser";
import Title from "../Title";
import {Img} from "../Img";
import {hover} from "../../styles/globalStyleVars";
// import {Img} from "../Img";

const Strength = ({title, id, padding, getAfterHeight, bg, img, full, project, nolnk, remove, data, after_bg}) => {


    const SwiperRef = useRef()
    const [getAfter, setgetAfter] = useState(90)
    const [offset, setOffset] = useState(90)
    useEffect(() => {


        // window.addEventListener('load', function () {
        setOffset(document.querySelector(' .container').offsetLeft)

        // })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])


    // half background calculation
    const [theHeight, setTheHeight] = useState()
    useEffect(() => {
        setTimeout(() => {
            if (window.innerWidth > 767) {
                const titleHeight = document.querySelector('.home .customers .title')?.clientHeight + 90
                const getItemHeight = document.querySelector('.home .customers .about-section__bottom__single')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight) + 200)
            } else {
                const titleHeight = document.querySelector('.home .customers .title')?.clientHeight
                const getItemHeight = document.querySelector('.home .customers .about-section__bottom__single')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight) + 265)
            }
        }, 1000)

        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                const titleHeight = document.querySelector('.home .customers .title')?.clientHeight + 90
                const getItemHeight = document.querySelector('.home .customers .about-section__bottom__single')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight) + 200)
            } else {
                const titleHeight = document.querySelector('.home .customers .title')?.clientHeight
                const getItemHeight = document.querySelector('.home .customers .about-section__bottom__single')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight) + 265)
            }
        })

    }, [data, SwiperRef])


    return (
        <StyledStrength theHeight={theHeight} getAfterHeight={getAfter} remove={remove} id={`${id ? id : 'Strength'}`}
                        offset={offset}
                        className={`pionneering parallax-inner-box pt-200 pb-200 customers`}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="d-flex navigation_wrapper">
                            <div className="title">
                                <Title text={data?.title}/>
                            </div>
                            {
                                data?.list?.length > 4 ?
                                    <ul className="navigation">
                                        <li className={'prev_swipper1 hover'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                                 viewBox="0 0 7.828 12.828">
                                                <g id="Group_6" data-name="Group 6"
                                                   transform="translate(1.414 11.414) rotate(-90)">
                                                    <line id="Line_4" data-name="Line 4" y1="5" x2="5" fill="none"
                                                          stroke="#fff"
                                                          stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_5" data-name="Line 5" x1="5" y1="5"
                                                          transform="translate(5)"
                                                          fill="none" stroke="#fff" stroke-linecap="round"
                                                          stroke-width="2"/>
                                                </g>
                                            </svg>

                                        </li>
                                        <li className={'next_swipper1 hover'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                                 viewBox="0 0 7.828 12.828">
                                                <g id="Group_6" data-name="Group 6"
                                                   transform="translate(-92.086 454.914) rotate(-90)">
                                                    <line id="Line_4" data-name="Line 4" x2="5" y2="5"
                                                          transform="translate(443.5 93.5)" fill="none" stroke="#fff"
                                                          stroke-linecap="round" stroke-width="2"/>
                                                    <line id="Line_5" data-name="Line 5" x1="5" y2="5"
                                                          transform="translate(448.5 93.5)" fill="none" stroke="#fff"
                                                          stroke-linecap="round" stroke-width="2"/>
                                                </g>
                                            </svg>

                                        </li>
                                    </ul> : ''

                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='about-section__bottom'>
                <Row>
                    {data?.list?.length > 0 &&
                        <Swiper ref={SwiperRef}
                                loop={true}
                                spaceBetween={30}
                                slidesPerView={4}
                                slideNextClass={'.next'}
                                allowSlideNext={true}
                                slidePrevClass={'.prev'}
                                allowSlidePrev={true}
                                allowTouchMove={true}
                                longSwipesMs={900}
                                speed={900}
                                autoplay={true}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 2,
                                        spaceBetween: 15,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 15,
                                    },
                                    1440: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                    1920: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },


                                }}
                                navigation={{
                                    prevEl: '.prev_swipper1',
                                    nextEl: '.next_swipper1  ',
                                }}
                                modules={[Navigation]}
                            // onSwiper={(swiper) => console.log(swiper)}
                        >
                            {
                                data?.list && data?.list?.length > 0
                                && data?.list?.map((element, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <Col sm={4} className='about-section__bottom__single p-0'>
                                                <div className={'single'}>
                                                    <a>
                                                        <div className="single_wrp">
                                                            <div className="content">
                                                                <Img src={element?.image}/>
                                                                <p>{ReactHtmlParser(element?.hover_text)} </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </Col>

                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    }
                </Row>
            </Container>
        </StyledStrength>
    );
};

const StyledStrength = styled.section`

  position: relative;
  background: #F6F6F6;
  overflow: hidden;


  &:after {
    height: calc(100% - ${(p) => p.theHeight + 'px'});
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    //background: red;
    content: "";
    display: ${props => props.remove ? 'none' : 'block'};
    opacity: ${props => props.remove ? '0' : '1'};
    //z-index: -1;
  }

  .view_btn {
    margin-top: 80px;
  }

  .title_wrapper {
    display: flex;
  }

  .swiper-initialized {
    //padding-right: 120px;
    padding-right: ${p => p.offset + 15}px;
    @media (max-width: 767px) {
      padding-right: 0 !important;
      width: 100%;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 80px;


    @media (max-width: 767px) {
      margin-bottom: 40px;
    }

    .title {
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #4F616B;
      }
    }

    .d-flex {
      justify-content: space-between;
    }

    .navigation {
      display: flex;
      align-items: center;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #404040;

        &.hover:after {
          background-color: ${hover};
        }

        &:first-child {
          margin-right: 20px;
        }

        position: relative;
        border-radius: 50%;
        height: 52px;
        width: 52px;

        svg {
          position: relative;

          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0;
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 26px;
            }
          }
        }
      }
    }
  }

  .swiper-wrapper {
    height: auto !important;
    padding-bottom: 20px;
  }

  .about-section__bottom {
    padding-left: ${props => props.offset ? props.offset + 30 + 'px' : '90px'};
    position: relative;


    .single_wrp {
      box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.05); /* horizontal-offset vertical-offset blur spread color */

      &::before {
      }

      position: relative;
      overflow: hidden;
      padding-top: calc(230 / 270 * 100%);
      box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.05);

      &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50vh;
        z-index: 1;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      }

      .content {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        justify-content: center;
        background: #fff;

        .global-image {
          //top: 50%;
          //left: 50%;
          //transform: translate(-50%,-50%) !important;
          //position: absolute !important;
          background-image: none !important;

          width: calc(100% - 80px);
          margin: auto;
          height: 120px;

          img {
            object-fit: contain !important;
          }
        }

        p {

          margin: 0 auto;
          font-weight: 500;
          width: 70%;
          text-align: center;
          position: relative;
          top: 20px;
          opacity: 0;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        }

        span {
          font-size: 100px;
          font-weight: 500;
          line-height: 97px;
          color: rgba(255, 255, 255, 0.3);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .global-image {
        img {
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          //transform: scale(1.01);
        }
      }

      &:hover {
        .global-image {
          img {
            top: -20px;
            opacity: 0;
          }
        }

        p {
          top: 0;
          opacity: 1;
        }
      }
    }

    //
    // &:after {
    //   height: 50%;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background: #F6F6F6;
    //   content: "";
      //   display: ${props => props.remove ? 'none' : 'block'};
      //   opacity: ${props => props.remove ? '0' : '1'};
    //   //z-index: -1;
    // }

    @media (max-width: 767px) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    &__single {
      max-width: 100%;
      //box-shadow: inset 0px -10px 10px -10px rgba(255, 255, 255, 0.5);
      box-shadow: 0 0 20px rgb(0 0 0 / 5%);
      min-height: unset;
      //@media (max-width: 767px) {
      //  min-height: 370px;
      //}

      &__inner {
        position: relative;
        padding-top: calc(230 / 270 * 100%);
        overflow: hidden;
        //border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: #E9E9E9;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0%;
          width: 100%;
          transition: .4s cubic-bezier(0.4, 0, 0, 1);
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ECECEC;
            mix-blend-mode: multiply;
            opacity: 1;
            transition: .8s cubic-bezier(0.4, 0, 0, 1);
            transition-delay: .3s;
          }
        }

        .hover-top {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 40px;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
          opacity: 0;
          transform: translateY(-100%);
          z-index: 2;

          h6 {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            color: #404040;
            margin-bottom: 20px;
          }

          ul {
            li {
              position: relative;
              padding-left: 20px;

              &:after {
                position: absolute;
                content: '';
                height: 10px;
                width: 10px;
                background-color: #C1C1C1;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
              }
            }
          }
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        h4 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          color: #FFF;
          z-index: 2;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
        }

        .icon {
          position: absolute;
          top: 30px;
          right: 30px;
          z-index: 1;
          transition: all .8s cubic-bezier(0.4, 0, 0, 1);
          transform: rotate(0deg);

          svg {
            li {
              transition: all .8s cubic-bezier(0.4, 0, 0, 1);

            }
          }

        }

        span {
          position: absolute;
          z-index: 1;
          top: 30px;
          left: 30px;
          font-size: 80px;
          line-height: 48px;
          color: #F9F9F9;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);

        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 100%;
          }

          .icon {
            transform: rotate(-45deg);

            svg {
              line {
                stroke: #061524;
                fill: #061524;
              }
            }
          }

          span {
            top: -10px;
            opacity: 0;
          }


          .hover-top {
            transform: none;
            opacity: 1;
            color: #4F616B;
          }


          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

          h4 {
            color: #061524;
          }

        }

      }
    }
  }

  .swiper {
    padding-left: 10px;
    padding-top: 20px;
  }

  @media (max-width: 767px) {
    .view_btn {
      display: none;
    }

    .navigation_wrapper {
      flex-wrap: wrap;
    }

    .navigation {
      min-width: 100%;
      margin-top: 40px;
      //margin-bottom: 20px;
    }

    .about-section__bottom__single__inner span {
      top: 55px;
      left: 55px;
    }

    .swiper {
      padding-left: 0px;
    }
  }


`;

export default Strength;