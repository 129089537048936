import styled from "styled-components";
import {Img} from "./Img";
import {hover} from "../styles/globalStyleVars";
import VisibilitySensor from "react-visibility-sensor";
import {Link} from "react-router-dom";

const BlogSingle = ({title, text, link, img, day, month, year, news_link}) => {


    return (
        <StyledBlogSingle className='blog-single'>
            <div className="blog-single__inner">
                {news_link ? <a href={news_link} target={'_blank'}/> : <Link to={link || '/blog/slug'}/>}

                {img && <Img src={img} objectFit={'cover'} layout={'fill'}/>}
                <div className="blog-single__inner__content">
                    <div className="blog-single__inner__content__top">
                        <p>{text}</p>
                        <div className="h3-container">
                            <h3>Read More</h3>
                        </div>
                        <h2>{title}</h2>
                    </div>
                    {
                        day && month && year ?
                            <div className="blog-single__inner__content__bottom">
                                <h4>{day}</h4>
                                <p>{month}
                                    <span>{year}</span></p>
                            </div>
                            : ''
                    }

                </div>
            </div>
        </StyledBlogSingle>
    );
};

const StyledBlogSingle = styled.div`
  //width: 100%;

  margin-bottom: 30px;

  .blog-single__inner {
    padding-top: calc(460 / 370 * 100%);
    position: relative;

    .global-image {
      overflow: hidden;

      img {
        transform: scale(1.01);
        transition: 1.3s ease;
      }
    }

    a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 3;
    }

    &__content {

      &__top {
        p {
          font-weight: 400;
          color: #ffffff;
          position: absolute;
          left: 40px;
          top: 40px;
          z-index: 2;
          right: 40px;
          margin: 0;
          transform: translateY(-30px);
          opacity: 0;
          transition: all .6s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        h3 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          color: #ffffff;
          position: absolute;
          left: 40px;
          top: 180px;
          z-index: 2;
          right: 40px;
          margin: 0;
          transform: translateY(-30px);
          opacity: 0;
          transition: all .6s ease;
          width: 50%;
          text-decoration: underline;
          text-decoration-color: white;
        }

        .h3-container {
          border-bottom: 1px solid white;
          width: fit-content;
        }

        h2 {
          position: absolute;
          top: 40px;
          left: 40px;
          right: 40px;
          z-index: 2;
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          color: #FFFFFF;
          margin: 0;
          transition: all .3s ease;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }

      &__bottom {
        position: absolute;
        margin: 0;
        left: 40px;
        right: 40px;
        bottom: 24px;
        display: flex;
        justify-content: space-between;
        //border-top: 1px solid #221F1F;
        padding-top: 20px;
        z-index: 2;

        h4 {
          font-size: 60px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 68px;
          transition: color .3s ease;
        }

        p {
          color: #FFFFFF;
          text-align: right;
          font-weight: 500;
          transition: color .3s ease;

          span {
            display: block;
            color: #FFFFFF;
          }
        }
      }
    }

    &:hover {
      .global-image img {
        transform: scale(1.06) !important;
      }

      .blog-single__inner__content__top {
        h2 {
          opacity: 0;
          transform: translateY(-20px);
        }

        p {
          transform: none;
          opacity: 1;
        }

        h3 {
          transform: none;
          opacity: 1;
        }
      }

      .blog-single__inner__content__bottom {
        border-color: #FFF;

        h4 {
          color: #ffffff;
        }

        p {
          color: #ffffff;

          span {
            color: #ffffff;
          }
        }
      }
    }

  }

  &:hover {
    .blog-single__inner__content:after {
      height: 0;
    }
  }


  @media (max-width: 767px) {
    margin-bottom: 40px;

    .blog-single__inner__content__top {
      p, h2 {
        left: 30px;
        right: 30px;
        top: 30px;
      }
    }

    .blog-single__inner__content__bottom h4, .blog-single__inner__content__bottom p {
      left: 30px;
      right: 30px;
      top: 30px;
    }

  }

`;
export default BlogSingle;
