export const apiEndPoints = {
    PAGE_DATA:'page-data',
    POSTS: 'posts',
    HOME_FORM: 'contact-form-7/v1/contact-forms/7/feedback',
    CAREER_FORM: 'contact-form-7/v1/contact-forms/110/feedback',
    HOME: 'page/home',
    ABOUT: 'page/about',
    CONTACT: 'page/contact',
    CAREER: 'page/career',
    PROJECT: 'page/projects',
    PROJECT_DETAIL: 'projects',
    GETPROJECT: 'getProjectList/all',
    MEDIA: 'page/media',
    MEDIA_DETAIL: 'media',
    GETMEDIA: 'getMediaList/all',
    DOMAIN: 'page/business-domain',
    SOCIAL: 'page/social-responsibility',
};
