import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import {BsPlus} from 'react-icons/bs';
import 'react-modal-video/css/modal-video.min.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import ReactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import Title from "../Title";
import {Img} from "../Img";
import {hover} from "../../styles/globalStyleVars";
import MediaSingle from "../MediaSingle";
import Button from "../Button";
// import {Img} from "../Img";

const Strength = ({title, id, padding, getAfterHeight, bg, img, full, project, nolnk, remove, data, after_bg}) => {
    const info = data?.posts?.list;
    const [getAfter, setgetAfter] = useState(90)
    const [offset, setOffset] = useState(90)

    const SwiperRef = useRef()

    useEffect(() => {
        // window.addEventListener('load', function () {
        setOffset(document.querySelector(' .container').offsetLeft)

        // })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data,SwiperRef])


    const NewsPrev13 = () => {
        document.querySelector('.swiper-button-prev').click();
    };

    const NewsNext13 = () => {
        document.querySelector('.swiper-button-next').click();
    };

    return (
        <StyledStrength getAfterHeight={getAfter} remove={remove} id={`${id ? id : 'Strength'}`} offset={offset}
                        className={`pionneering parallax-inner-box pb-200`}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="d-flex navigation_wrapper">
                            <div className="title">
                                <Title text={'<span>Media</span> center'}/>
                            </div>
                            <ul className="navigation">
                                <li className={'prev_swipper hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                         viewBox="0 0 7.828 12.828">
                                        <g id="Group_6" data-name="Group 6"
                                           transform="translate(1.414 11.414) rotate(-90)">
                                            <line id="Line_4" data-name="Line 4" y1="5" x2="5" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_5" data-name="Line 5" x1="5" y1="5" transform="translate(5)"
                                                  fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>

                                </li>
                                <li className={'next_swipper hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                         viewBox="0 0 7.828 12.828">
                                        <g id="Group_6" data-name="Group 6"
                                           transform="translate(-92.086 454.914) rotate(-90)">
                                            <line id="Line_4" data-name="Line 4" x2="5" y2="5"
                                                  transform="translate(443.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_5" data-name="Line 5" x1="5" y2="5"
                                                  transform="translate(448.5 93.5)" fill="none" stroke="#fff"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>

                                </li>
                                <Button text={'View All'} src={'/media-center'}/>

                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='about-section__bottom'>
                <Row>
                    {
                        data?.length > 0 &&
                        <Swiper
                            ref={SwiperRef}
                            loop={true}
                            spaceBetween={30}
                            slidesPerView={3}
                            slideNextClass={'.next'}
                            allowSlideNext={true}
                            slidePrevClass={'.prev'}
                            allowSlidePrev={true}
                            allowTouchMove={true}
                            longSwipesMs={900}
                            speed={900}
                            autoplay={true}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}
                            navigation={{
                                prevEl: '.prev_swipper',
                                nextEl: '.next_swipper  ',
                            }}
                            modules={[Navigation]}
                            // onSwiper={(swiper) => console.log(swiper)}
                        >
                            {
                                data && data.length > 0 &&
                                data.map((element, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <Col sm={4} className='about-section__bottom__single p-0'>
                                                <MediaSingle
                                                    text={element?.hover_title}
                                                    title={element?.post_title}
                                                    img={element?.thumb}
                                                    link={`media-center/${element?.post_name}`}
                                                    day={element?.date}
                                                    month={element?.month}
                                                    year={element?.year}
                                                    news_link={element?.link}
                                                />
                                            </Col>

                                        </SwiperSlide>
                                    )
                                })
                            }


                        </Swiper>
                    }


                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={2} md={4} className={'view_btn'}>
                        <Button text={'View All'} src={'/media-center'}/>
                    </Col>
                </Row>
            </Container>
        </StyledStrength>
    );
};

const StyledStrength = styled.section`

  position: relative;
  overflow: hidden;

  .view_btn {
    margin-top: 80px;
  }

  .title_wrapper {
    display: flex;
  }

  .swiper-initialized {
    padding-right: ${p => p.offset + 15}px;
    @media (max-width: 767px) {
      padding-right: 0 !important;
      width: 100%;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 80px;

    .dc-btn {
      display: none;
    }

    @media (max-width: 767px) {
      margin-bottom: 40px;
      .dc-btn {
        display: block;
        position: absolute;
        right: 15px;
        //margin-left: 113px;
      }

    }

    .title {
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #4F616B;
      }
    }

    .d-flex {
      justify-content: space-between;
    }

    .navigation {
      display: flex;
      align-items: center;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #404040;

        &.hover:after {
          background-color: ${hover};
        }

        &:first-child {
          margin-right: 20px;
        }

        position: relative;
        border-radius: 50%;
        height: 52px;
        width: 52px;

        svg {
          position: relative;

          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0;
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 26px;
            }
          }
        }
      }
    }
  }

  .swiper-wrapper {
    height: auto !important;
  }

  .about-section__bottom {
    //margin-top: -50px;
    padding-left: ${props => props.offset ? props.offset + 30 + 'px' : '90px'};
    position: relative;
    //
    // &:after {
    //   height: 50%;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   background: #F6F6F6;
    //   content: "";
      //   display: ${props => props.remove ? 'none' : 'block'};
      //   opacity: ${props => props.remove ? '0' : '1'};
    //   //z-index: -1;
    // }

    @media (max-width: 767px) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    &__single {
      max-width: 100%;
      @media (max-width: 767px) {
        min-height: 370px;
      }

      &__inner {
        position: relative;
        padding-top: calc(460 / 370 * 100%);
        overflow: hidden;
        //border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: #E9E9E9;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0%;
          width: 100%;
          transition: .4s cubic-bezier(0.4, 0, 0, 1);
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ECECEC;
            mix-blend-mode: multiply;
            opacity: 1;
            transition: .8s cubic-bezier(0.4, 0, 0, 1);
            transition-delay: .3s;
          }
        }

        .hover-top {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 40px;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
          opacity: 0;
          transform: translateY(-100%);
          z-index: 2;

          h6 {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            color: #404040;
            margin-bottom: 20px;
          }

          ul {
            li {
              position: relative;
              padding-left: 20px;

              &:after {
                position: absolute;
                content: '';
                height: 10px;
                width: 10px;
                background-color: #C1C1C1;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
              }
            }
          }
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s cubic-bezier(0.4, 0, 0, 1);

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s cubic-bezier(0.4, 0, 0, 1);
          }
        }

        h4 {
          position: absolute;
          bottom: 30px;
          left: 30px;
          right: 30px;
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          color: #FFF;
          z-index: 2;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);
        }

        .icon {
          position: absolute;
          top: 30px;
          right: 30px;
          z-index: 1;
          transition: all .8s cubic-bezier(0.4, 0, 0, 1);
          transform: rotate(0deg);

          svg {
            li {
              transition: all .8s cubic-bezier(0.4, 0, 0, 1);

            }
          }

        }

        span {
          position: absolute;
          z-index: 1;
          top: 30px;
          left: 30px;
          font-size: 80px;
          line-height: 48px;
          color: #F9F9F9;
          transition: all .5s cubic-bezier(0.4, 0, 0, 1);

        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 100%;
          }

          .icon {
            transform: rotate(-45deg);

            svg {
              line {
                stroke: #061524;
                fill: #061524;
              }
            }
          }

          span {
            top: -10px;
            opacity: 0;
          }


          .hover-top {
            transform: none;
            opacity: 1;
            color: #4F616B;
          }


          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

          h4 {
            color: #061524;
          }

        }

      }
    }
  }

  @media (max-width: 767px) {
    .view_btn {
      display: none;
    }

    .navigation_wrapper {
      flex-wrap: wrap;
    }

    .navigation {
      min-width: 100%;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .about-section__bottom__single__inner span {
      top: 55px;
      left: 55px;
    }
  }


`;

export default Strength;