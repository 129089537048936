import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {Img} from "../Img";


const MyComponent = ({data,id,getoffset}) => {


    const [offset, setoffset] = useState(90)

    useEffect(() => {
        setoffset(document.querySelector(' .container').offsetLeft)
        window.addEventListener('load', function () {
            setoffset(document.querySelector(' .container').offsetLeft)
            // document.querySelector('#controlled-tab-example-tab-tab80').click();
        })
        window.addEventListener('resize', function () {
            setoffset(document.querySelector(' .container').offsetLeft)

        })
    }, [])

    return (

        <StyledComponent  getoffset={offset} id={`${id ? id : 'AddressTab'}`} offset={offset} className={`addresstab pt-100 divider`}>
            <Container fluid>
                <Row>
                    <div className="tab_container">
                        <Col md={12} >
                            <div className="custom_pad row m-0 pb-100">
                                <Col lg={2} xs={9}>
                                    <h3>{data?.title}</h3>
                                </Col>
                                <Col lg={{span:3,offset:1}} xs={12}>
                                    <a href={data?.gmap_link} target={'_blank'}>
                                        <p>{data?.location}</p>
                                    </a>
                                </Col>
                                <Col lg={{span:2,offset:1}} xs={9} className={'phone'}>
                                    {/*{ReactHtmlParser(data?.phone)}*/}



                                    <h5>t : {ReactHtmlParser(data?.phone)}</h5>
                                    {/*<h5>t : <a href={`tel:+88 0248814284`}>+88 0248814284</a></h5>*/}
                                    {/*<h5>t : <a href={`tel:${data?.phone ? data?.phone : '+880 111 222 333'}`}>{data?.phone}</a></h5>*/}
                                    {/*<h5>t : <a href={`tel:${data?.phone ? data?.phone : '+880 111 222 333'}`}>{data?.phone}</a></h5>*/}

                                </Col>
                                <Col lg={{span:3}} xs={9}>
                                    <h5>e : <a href={`mailto:${data?.email ? data?.email : 'info@ndeinfratech.com'}`}>{data?.email}</a></h5>

                                </Col>
                            </div>
                            <Row>
                                <div className="clearfix"></div>
                                <Col lg={12} className={'google_map'}>
                                    <a target={'_blank'} href={`${data?.gmap_link}`}>
                                        <div className={'map_img'}>
                                            <Img src={data?.image}/>
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  padding-top: 80px;
  
  .col-lg-3, .col-lg-2{
    padding-left: 0px !important;
  }
  
  h3{
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
  }
  h5{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500 !important;

  }
  p,a{
    font-weight: 500 !important;
    &:hover{
      color: #1961AC !important;
    }
  }

@media(max-width: 991px){
  p,h3{
    margin-bottom: 30px;
  }
  .col-md-2{
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0px;

    }
   
  }
}
  @media(max-width: 992px){
    overflow: hidden;
  }
  @media(max-width: 767px){
    //.google_map{
    //  padding: 0;
    //}
  }
  .map_img{
    position: relative;
    padding-top: calc(560/1268*100%);
  }
  .formobile{
    display: none;
    @media(max-width: 767px){
      display: block;
      margin: 0;
      padding: 0;
    }
  }
  &:after{
    height:65%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: #E9E9E9;
    width: 100%;
    z-index: 1;
    content: "";
    @media(max-width: 991px){
      height:75%;
    }
    @media(max-width: 767px){
      height:80%;
    }
    @media(max-width: 400px){
      height:85%;
    }
  }
  .container-fluid{
    position: relative;
    z-index: 2;
    margin: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: ${props => props.getoffset ? props.getoffset+15+'px'  : ''};
    .google_map{
      padding-right: 0;
    }

    @media(max-width: 767px){
      padding: 0 15px !important;
    }

  }

  .custom_pad{
    padding-right:  ${props => props.getoffset ? props.getoffset+15+'px'  : ''};
    .phone{
      margin-bottom: 20px;
    }

  }
  .tab_container{
    width: 100%;
    .nav-tabs{
      border: none;
      padding-right:  ${props => props.getoffset ? props.getoffset+15+'px'  : ''};

      @media(max-width: 767px){
        display: none;
      }
      .nav-item{
        border: none;
        margin-right: 20px;
        &:last-child{
          margin-right: 0;
        }
        .nav-link{
          border: 1px solid #F9F9F9;
          height: 45px;
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
          background: #4F616B;
          border-radius: 25px;
          padding: 0 30px;
          line-height: 45px;
          position: relative;
          transition: 0.8s all cubic-bezier(0.4, 0, 0, 1);

          &:after{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: white;
            content: "";
            border-radius: 25px;
            transition: 0.8s all cubic-bezier(0.4, 0, 0, 1);
            transform:scale(0);
            z-index: -1;
          }
          &:hover{
            color: #4F616B;
            &:after{
              transform: scale(1);

            }
          }
          &.active{
            color: #4F616B;
            background: transparent;
            &:after{
              transform: scale(1);

            }
          }
        }

      }
    }

    .google_map{
      //margin-top: 40px;
    }

    .tab-pane{
      padding-top: 80px;
      @media(max-width: 767px){
        padding-top: 40px;
        .col-md-3, .col-md-4{
          margin-bottom: 20px;
        }
      }
      p,a{
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        display: block;
        margin-bottom: 10px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      h3{
        color: #F9F9F9;
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
      }
    }
  }

  .css-13cymwt-control{
    border-radius: 0;
    .css-1u9des2-indicatorSeparator{
      display: none;
    }
  }
`;

export default MyComponent;