import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    detail: [],
    detailLoading: false,
    detailError: '',
    page_data:[]
}
export const fetchPageData = createAsyncThunk("fetchBanner", (params) => {
    return get(params);
});
export const fetchPosts = createAsyncThunk("fetchData", (params) => {
    return get(params);
});

export const fetchPostDetail = createAsyncThunk("fetchDataDetail", (params) => {
    return get(params);
});

// export const fetchPosts = createAsyncThunk('posts/fetchAllPosts', () => {
//     return axios.get('https://jsonplaceholder.typicode.com/posts')
//         .then(response => response.data)
// })
//
// export const fetchPostDetail = createAsyncThunk('posts/fetchPostsDetail', (param) => {
//     return axios.get(`https://jsonplaceholder.typicode.com/posts/${param}`)
//         .then(response => response.data)
// })

const postSlice = createSlice({
    name: 'posts',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchPosts.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchPosts.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchPosts.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        builder.addCase(fetchPageData.pending, (state) => {
            state.loading = true
            state.page_data = []
            state.error = ''
        })
        builder.addCase(fetchPageData.fulfilled, (state, action) => {
            state.loading = false
            state.page_data = action.payload
            state.error = ''
        })
        builder.addCase(fetchPageData.rejected, (state, action) => {
            state.loading = false
            state.page_data = []
            state.error = action.error
        })
        builder.addCase(fetchPostDetail.pending, (state, action) => {
            state.detailLoading = true
            state.detail = []
            state.detailError = ''
        })
        builder.addCase(fetchPostDetail.fulfilled, (state, action) => {
            state.detailLoading = false
            state.detail = action.payload
            state.detailError = ''
        })
        builder.addCase(fetchPostDetail.rejected, (state, action) => {
            state.detailLoading = false
            state.detail = []
            state.detailError = action.error
        })
    }
})


export default postSlice.reducer
