import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Form, Row} from "react-bootstrap";
import {Img} from "../Img";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {emailValidation, emptyNumber, emptyValidation} from "../../api/config/validator";
import Button from "../Button";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useForm} from "react-hook-form";
import {postForm} from "../../api/redux/Contact";

const MyComponent = ({data}) => {

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    const {register, handleSubmit ,formState,reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };



    const onSubmit = (e) => {

        let api_services = apiEndPoints.HOME_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('your-message', e?.message);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
    };
    let count=0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if(count>0){
            toast.error('please fill out the correct inputs');
        }
        count=0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            // success(responseData?.success)
        }

    }, [responseData])


    return (
        <StyledComponent className={'contact-form pt-200 pb-200'}>
            <Container>
                <Row>
                    <Col lg={6} md={12}>
                        <div className={'contact-form__img'}>
                            <Img src={data?.image}/>
                        </div>
                    </Col>
                    <Col lg={{span:5,offset:1}} md={12}>
                        <Form className={'form'}  onSubmit={handleSubmit(onSubmit,onError)}>
                            <Col md={12}>
                                <h3>{data?.title}</h3>
                            </Col>

                            <input name={'spam_protector'} type='hidden'/>

                            <Col sm={12}>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                  {...register("name",{
                                                      required: 'Username is required',
                                                      pattern: {
                                                          value: /^[A-Za-z]+$/,
                                                          message: 'Username must contain only letters',
                                                      },

                                                  })}
                                                  type="text"
                                                  placeholder="Full name"
                                    />
                                </Form.Group>

                            </Col>

                            <Col sm={12}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control  className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                   {...register("email",{
                                                       required:{
                                                           value:true,
                                                           message:'please enter your email'
                                                       },
                                                       pattern:{
                                                           value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                           message:'please enter a valid email address'
                                                       }
                                                   })}
                                                   type="email"
                                                   placeholder="Email*"/>
                                </Form.Group>
                            </Col>

                            <Col sm={12} >

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                  {...register("phone",{
                                                      required:{
                                                          value:true,
                                                          message:'please enter your phone first'
                                                      },
                                                      pattern:{
                                                          value:/^01[0-9]{9}$/,
                                                          message:'please enter a valid 11 digit phone number'
                                                      }
                                                  })}
                                                  type="number"
                                                  placeholder="Phone Number*"/>
                                </Form.Group>

                            </Col>


                            <Col sm={12}>
                                {/*<textarea name="" id="" placeholder={'Message*'}/>*/}
                                <Form.Control className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                              {...register("message",{
                                                  required:{
                                                      // value:true,
                                                      message:'please enter your email'
                                                  },

                                              })}
                                              type="text"
                                              placeholder="Message (Optional)"/>
                            </Col>

                            <Col sm={12} >
                                <div onClick={handleSubmit(onSubmit,onError)}>
                                    <Button border={'1px solid #FFFFFF'}  background={'#061524'} color={'#FFF'} text={'Submit Message'} width={'55%'} height={'40px'}  borderRadius={'20'} />
                                </div>
                            </Col>

                        </Form>

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  .col-lg-6{
    padding-right: 0px;
  }
  .col-lg-5{
    padding-left: 0px;
  }

  h3{
    color: #FFFFFF !important;
    margin-bottom: 40px !important;
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
  }
  .contact-form{
    &__img{
      position: relative;
      //padding-top: calc(542/630 * 100%);
      height: 100%;
      width: 100%;
    }
  }
  input:-webkit-autofill{
    color: white !important;
  }
  @media(max-width: 991px){
    .contact-form{
      &__img{
        position: relative;
        padding-top: calc(542/630 * 100%);
        height: 100%;
        width: 100%;
      }
    }
  }

  @media(min-width: 768px) and (max-width: 991px){
    h3{
      margin-left: 15px;
    }
    .col-md-12{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .container{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media(max-width: 767px){

    .row{
      margin-left: -30px;
      margin-right: -30px;
    }
  }
  @media (min-width: 340px) and (max-width: 400px) {
    .dc-btn {
      width: 65% !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1499px) {
    .dc-btn {
      width: 60% !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .dc-btn {
      width: 80% !important;
    }
  }

`
export default MyComponent;
