import React, {useEffect} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Form, Row} from "react-bootstrap";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import ReactHtmlParser from "react-html-parser";
import Button from "./Button";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {hover, text} from "../styles/globalStyleVars";
import {postForm} from "../api/redux/Career";

const Popup = ({
                   show,
                   handleClose,
                   no_img,
                   item,
                   title,
                   description,
                   data,
                   subtitle,
                   img, toptitle
               }) => {

    const popupData = data;


    const dispatch = useDispatch();
    const responseData = useSelector(state => state.homeReducer);

    //To hide the menu while opening the popup

    // useEffect(()=>{
    //     document.querySelector('.dc-btn').addEventListener('click', () => {
    //         if (document.body.classList.contains('scroll-up')) {
    //             document.body.classList.remove('scroll-up');
    //             document.body.classList.add('scroll-down');
    //         }
    //     })
    //
    // },[])

    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });


    const onSubmit = (e) => {

        let api_services = apiEndPoints.CAREER_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-phone', e?.phone);
        formData.append('your-message', e?.message);
        formData.append('file-771', e?.file?.[0]);
        if (e.email !== '' && e.name !== '' && e.phone !== '' && e?.file?.[0] !== '') {
            dispatch(postForm([api_services, formData]))
            toast.success('successfully Submitted');
            reset();
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }

        count = 0;

    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error)
        }
        if (responseData && responseData?.success !== '') {
            success(responseData?.success)
        }

    }, [responseData])

    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal"
            >
                <Modal.Header closeButton>
                    <Container>
                        <Row>
                            <Col className="header_wrap">
                                <h3>{data?.title}</h3>
                                <div onClick={handleClose} className="btn-circle hover">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.829" height="10.828"
                                         viewBox="0 0 10.829 10.828">
                                        <g id="Group_18979" data-name="Group 18979"
                                           transform="translate(-3165.586 -121.086)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8"
                                                  transform="translate(3167 122.5)" fill="none" stroke="#3b3838"
                                                  stroke-linecap="round" stroke-width="2"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8"
                                                  transform="translate(3167 122.5)" fill="none" stroke="#3b3838"
                                                  stroke-linecap="round" stroke-width="2"/>
                                        </g>
                                    </svg>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Header>
                <SimpleBar className="main_scroll" style={{height: '68vh'}}>
                    <Modal.Body className={'pb-100'}>
                        <Container>
                            <Row>
                                <Col className="left_col p-0" lg={6} md={12}>
                                    <SimpleBar className="custombar" style={{height: '70vh'}}>
                                        <div className="left_col__content">
                                            <div className="content ">
                                                <h3>Overview</h3>
                                                <p>{data?.overview}</p>
                                                <h3>Responsibilities</h3>
                                                <ul className="custom-list">
                                                    {ReactHtmlParser(data?.responsibilities)}
                                                    {/*<li>Have a good knowledge Digital Marketing Strategy</li>*/}
                                                    {/*<li>Creative planning and execution of all the digital marketing campaigns, including good Graphics.</li>*/}
                                                    {/*<li>Designing Knowledge & content writing.</li>*/}

                                                </ul>
                                                <h3>Educational Requirements</h3>
                                                <ul className="custom-list">
                                                    {ReactHtmlParser(data?.requirements)}

                                                    {/*<li>Bachelor degree in any discipline.</li>*/}
                                                    {/*<li>Training/Trade Course in Digital Marketing.</li>*/}
                                                </ul>
                                                {/*{ReactHtmlParser(description)}*/}
                                            </div>
                                        </div>
                                    </SimpleBar>
                                </Col>
                                <Col lg={{span: 5, offset: 1}} md={12} sm={12}>
                                    <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>

                                        <input name={'spam_protector'} type='hidden'/>

                                        <Col sm={12}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control
                                                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                    {...register("name", {
                                                        required: {
                                                            value: true,
                                                            message: 'please enter your username first'
                                                        }
                                                    })}
                                                    type="text"
                                                    placeholder="Full name"/>
                                            </Form.Group>
                                        </Col>

                                        <Col sm={12}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control
                                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                    {...register("email", {
                                                        required: {
                                                            value: true,
                                                            message: 'please enter your email'
                                                        },
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: 'please enter a valid email address'
                                                        }
                                                    })}
                                                    type="email"
                                                    placeholder="Email address *"/>
                                            </Form.Group>
                                        </Col>

                                        <Col sm={12}>

                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control
                                                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                    {...register("phone", {
                                                        required: {
                                                            value: true,
                                                            message: 'please enter your phone first'
                                                        },
                                                        pattern: {
                                                            value: /^01[0-9]{9}$/,
                                                            message: 'please enter a valid 11 digit phone number'
                                                        }
                                                    })}
                                                    type="number"
                                                    placeholder="Phone number *"/>
                                            </Form.Group>

                                        </Col>


                                        <Col sm={12}>
                                            {/*<textarea name="" id="" placeholder={'Message*'}/>*/}
                                            <Form.Control
                                                className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                {...register("message", {
                                                    required: {
                                                        value: true,
                                                        message: 'please enter your email'
                                                    },

                                                })}
                                                type="text"
                                                placeholder="Message (Optional)"/>
                                        </Col>

                                        <Col sm={12} className={'d-flex justify-content-between'}>
                                            <div className="form-right__box">
                                                <Form.Control
                                                    type="file"
                                                    accept=".pdf"
                                                    {...register('file')}
                                                />
                                            </div>
                                            <div onClick={handleSubmit(onSubmit, onError)}>
                                                <Button border={'1px solid #FFFFFF'} hoverBackground={''}
                                                        background={'#061524'} color={'#FFF'} text={'Submit'}
                                                        width={'126px'} borderRadius={'20'}/>
                                            </div>

                                        </Col>

                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

  .col-md-5 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .btn-circle {
    position: absolute;
    right: 20px;
    bottom: -26px;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background-color: ${text};

    &:hover {
      svg {
        fill: white;
      }

      .btn-circle:after {
        top: 0;
      }
    }
  }

  .modal-dialog {
    margin: 0;
  }

  h4 {
    letter-spacing: 1.5px;
  }

  @media (min-width: 1550px) {
    h3 {
      font-size: 40px;
      line-height: 56px;
    }
  }
  @media (max-width: 767px) {

    .col-md-5 {
      padding-left: 0px !important;
      padding-right: 0px !important;

    }

    h3 {
      font-size: 24px;
      line-height: 32px;
    }

  }

  .form-right {
    position: relative;

    &__box {
      height: 44px;
      width: 205px;
      border: none;


      &:hover {
        border: none;
      }

      input {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border: 1px solid #ED5333;
        border-radius: 26px;

        &:focus {
          outline: none;
          border: 1px solid #ED5333;
        }

        &:after {
          background-image: url('/images/static/upload-svg.svg');
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          bottom: 0;
          transition: all 0.25s ease-in-out;
          background-color: #F0EDE3;
          z-index: 2;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ED5333;
          white-space: normal;
          text-align: center;
          cursor: pointer;
          background-repeat: no-repeat;
          background-position: 30px 50%;
          padding-left: 30px;
          transition-delay: 0.15s;
        }
      }

      @media (max-width: 767px) {
        margin-bottom: 40px;
        width: 100%;
      }
    }
  }

  @media (max-width: 1200px) {
    .col-lg-5 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
`;


export default Popup;
