import React, {useEffect} from 'react';
import styled from "styled-components";

import {useLocation, useParams} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPostDetail} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {Helmet, HelmetProvider} from 'react-helmet-async'
import ProjectDetail from "../../components/Projects/ProjectDetail";
import GetInTouch from "../../components/GetInTouch";
import {Loading} from "../../components/Loading";
import ProjectDetailV2 from "../../components/Projects/ProjectDetailV2";

const MyComponent = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const getData = useSelector(store => store.posts)
    let {slug} = useParams();
    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PROJECT_DETAIL
        dispatch(fetchPostDetail([api_url + `/${slug}`]))
    }, [location.pathname])

    useEffect(() => {
        window.scroll(0, 0)
    }, [])


    let meta =getData?.detail?.[0]?.meta;


    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : getData?.detail?.[0]?.post_title} | NDE Infratech`}</title>
                <meta name="description" content={meta && meta.meta_desc ? meta.meta_desc
                    : 'NDE Infratech specializes in designing & implementing hardware, software and other cutting-edge technological ' +
                    'solutions in the public infrastructure domain. We offer products and efficient customized solutions ' +
                    'through our wide network of partners, spread across the globe. With a vast experience in the public sector,' +
                    ' we ensure seamless implementation and execution of a diverse range of projects.'}/>
            </Helmet>


            <StyledComponent>
                {/*{getData?.detailLoading && <Loading/>}*/}
                {/*<ProjectDetail data={getData?.detail}/>*/}
                <ProjectDetailV2 data={getData?.detail}/>
                <GetInTouch data={getData?.detail?.[0]?.footer_top} detail/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
