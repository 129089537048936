import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Button from "../Button";
import PopUp from "../PopUp";

const MyComponent = ({data}) => {


    const title = 'Deputy Manager/ Manager - Structural Engineer';
    const desc = 'Deputy Manager/ Manager - Structural Engineer';
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [popupData, setPopupData] = useState('')
    const handlePopup = (data) => {
        setPopupData(data)
    }


    return (
        <StyledComponent className={'jobs pt-100 pb-200 divider'}>
            <Container>
                {
                    data && data.length > 0 &&
                    data.map((element, index) => {
                        return (
                            <Row key={index} onClick={() => handlePopup(element)} key={index}>
                                <Col md={4}>
                                    {element?.title && <h2 className={'split-up'}>{element?.title}</h2>}
                                </Col>
                                <Col md={{span: 3, offset: 1}}>
                                    {element?.status && <p className={'split-up'}>{element?.status}</p>}
                                    {element?.experience && <p className={'split-up'}>{element?.experience}</p>}
                                </Col>
                                <Col md={{span: 2, offset: 2}}>
                                    <div className="bordered-row" onClick={handleShow}>
                                        <Button text={'View Details'} margin={'0 0 0 0'} width={'168px'}/>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className={'border-bottom-class'}/>
                                </Col>
                            </Row>
                        )
                    })
                }

            </Container>
            <PopUp show={show} toptitle={title} handleClose={handleClose} description={desc} data={popupData}/>

        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }
  

  p {
    :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .container {

    @media (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .bordered-row {
    //border-bottom: 1px solid rgba(64, 64, 64, 0.5);
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
      border-bottom: none;
    }
    @media (max-width: 767px) {
      padding-bottom: 60px;
      justify-content: start;


    }

  }

  .border-bottom-class {
    padding-bottom: 40px;
    margin-right: 15px !important;
    border-bottom: 1px solid rgba(64, 64, 64, 0.5) !important;

    :not(:last-child) {
      @media (max-width: 767px) {
        border-bottom: none;

      }
    }

    :not(:first-child) {
      margin-top: 40px;
    }

  }

  .row {
    :last-child {
      .border-bottom-class {
        border-bottom: 1px solid transparent !important;
        padding-bottom: 0px !important;


      }
    }


    :not(:last-child) {
      //border-bottom: 1px solid rgba(64, 64, 64, 0.5);
      @media (max-width: 767px) {
        border-bottom: none;
        .border-bottom-class {
          margin-right: 0px;

        }
      }
    }

    :not(:first-child) {
      margin-top: 40px;
    }
  }


  @media (max-width: 767px) {
    h2 {
      margin-bottom: 40px;
    }

    p {
      :last-child {
        margin-bottom: 40px;
      }
    }

    .row {
      margin-bottom: 60px;
    }

    .dc-btn {
      width: 50%;
    }
  }


`
export default MyComponent;