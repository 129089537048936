import React, {useEffect} from 'react';
import styled from "styled-components";
import InnerBannerV2 from "../../components/InnerBannerV2";
import ESG from "../../components/responsibility/ESG";
import GetInTouch from "../../components/GetInTouch";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchEsg} from "../../api/redux/Esg";
import {gsap} from "gsap";
import {useLocation} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

const MyComponent = () => {
    const dispath = useDispatch()
    const location = useLocation()

    useEffect(() => {
        window.scroll(0, 0)
    }, [location])

    //Api call
    useEffect(() => {
        let api_url = apiEndPoints.SOCIAL
        dispath(fetchEsg([api_url]))

    }, [])

    let getPost = useSelector(state => state.esg);

    const banner = getPost?.posts?.page_data?.banner;
    const footerTop = getPost?.posts?.page_data?.footer_top;
    const esg = getPost?.posts?.social_list;

    const meta = getPost?.posts?.page_data?.meta;




    // scroll to section
    useEffect(() => {
        if (location.hash && !getPost?.loading) {
            gsap.to(window, {
                // delay: .6,
                duration: .8,
                scrollTo: location.hash,
                // ease: "Expo.easeInOut",
            })
        }
    }, [location, getPost])


    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : 'Social Responsibility'} | NDE Infratech`}</title>
                <meta name="description" content={meta && meta.meta_desc ? meta.meta_desc
                    : 'NDE Infratech specializes in designing & implementing hardware, software and other cutting-edge technological ' +
                    'solutions in the public infrastructure domain. We offer products and efficient customized solutions ' +
                    'through our wide network of partners, spread across the globe. With a vast experience in the public sector,' +
                    ' we ensure seamless implementation and execution of a diverse range of projects.'}/>
            </Helmet>
            <StyledComponent>
                <InnerBannerV2 img={banner?.image} title={banner?.title}  mobile_img={banner?.mobile_image} responsibility/>
                <div className={'pt-100'}>
                    {
                        esg && esg.length > 0 &&
                        esg.map((element, index) => {
                            if (index % 2 == 0) {
                                return (
                                    <ESG title={element?.post_title} short_desc={element?.detail_info?.short_desc}
                                         images={element?.detail_info?.list}/>
                                )
                            } else {
                                return (
                                    <ESG title={element?.post_title} short_desc={element?.detail_info?.short_desc}
                                         images={element?.detail_info?.list} reverse/>
                                )
                            }
                        })
                    }
                </div>
                <GetInTouch data={footerTop}/>
            </StyledComponent>
        </HelmetProvider>

    );
};
const StyledComponent = styled.section`
  .swiper-button-prev .swiper-button-next {
    display: none;
  }

`
export default MyComponent;