import React, {useEffect} from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchContact} from "../../api/redux/Contact";
import ContactForm from "../../components/contact/ContactForm";
import AddressTab from "../../components/contact/AddressTab";
import InnerBannerV2 from "../../components/InnerBannerV2";
import GetInTouch from "../../components/GetInTouch";
import {Loading} from "../../components/Loading";

const MyComponent = () => {


    const dispath = useDispatch()
    let getPost = useSelector(state => state.contact)


    //Refactor Data

    const banner = getPost?.posts?.[0]?.banner;
    const footerTop = getPost?.posts?.[0]?.footer_top;
    const address = getPost?.posts?.[0]?.address;
    const form = getPost?.posts?.[0]?.form;
    const meta = getPost?.posts?.[0]?.meta;

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.CONTACT
        dispath(fetchContact([api_url]))
    }, [])

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : 'Contact'} | NDE Infratech`}</title>
                <meta name="description" content={meta && meta.meta_desc ? meta.meta_desc
                    : 'NDE Infratech specializes in designing & implementing hardware, software and other cutting-edge technological ' +
                    'solutions in the public infrastructure domain. We offer products and efficient customized solutions ' +
                    'through our wide network of partners, spread across the globe. With a vast experience in the public sector,' +
                    ' we ensure seamless implementation and execution of a diverse range of projects.'}/>
            </Helmet>

            <StyledComponent>
                {/*{getPost?.loading && <Loading/>}*/}
                <InnerBannerV2 img={banner?.image} title={banner?.subtitle} text={banner?.title}  mobile_img={banner?.mobile_image}/>
                <AddressTab data={address}/>
                <ContactForm data={form}/>
                <GetInTouch data={footerTop}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
